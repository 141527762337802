import { useStoreState } from 'easy-peasy';
import { useEffect, useState } from 'react';
import routes from '../../../config/routes';
import IConfig, { IAlertClass } from '../../../models/config';
import IIndex, { IAlertRegion } from '../../../models/index';
import { momentFullFormat } from '../../../service/chartDataHandler';
import { IState } from '../../../store/store';
import { getAlertRegionColor } from '../../../utils/mapUtils';
import EAccordeon from '../../elements/accordeon/EAccordeon';
import EMessage from '../../elements/message/EMessage';
import './MAlertInfo.scss';

interface IProps {
    index: IIndex;
    config: IConfig;
}

const MAlertInfo = ({ config, index }: IProps) => {
    const [alertRegion, setAlertRegion] = useState<IAlertRegion>();
    const [alertClass, setAlertClass] = useState<IAlertClass>();
    const [alertColor, setAlertColor] = useState<string>();
    const selectedAlertRegionId: number = useStoreState<IState, number>((state) => state.selectedAlertRegion.id);

    useEffect(() => {
        if (!config || !index) {
            return;
        }

        if (selectedAlertRegionId) {
            const selectedAlertRegion = index.alertregions[selectedAlertRegionId];
            setAlertRegion(selectedAlertRegion);
            setAlertClass(config.alertclasses[selectedAlertRegion.alertClassId]);
            setAlertColor(getAlertRegionColor(selectedAlertRegionId, config, index));
        } else {
            // Get highest warning.
            let highestAlertClassId = -1;
            Object.values(index.alertregions).forEach((indexAlertRegion) => {
                if (indexAlertRegion.alertClassId > highestAlertClassId) {
                    highestAlertClassId = indexAlertRegion.alertClassId;
                }
            });
            if (highestAlertClassId > -1) {
                setAlertClass(config.alertclasses[highestAlertClassId]);
            }
        }
    }, [config, index, selectedAlertRegionId]);

    return (
        <div className="m-alert-info">
            {alertRegion ? (
                <div className="m-alert-info__box">
                    <svg className="m-alert-info__color-column">
                        <rect width="100%" height="100%" fill={alertColor} />
                    </svg>

                    <div className="m-alert-info__body">
                        <EAccordeon externalIsOpen={true} key={selectedAlertRegionId}>
                            {{
                                heading: (
                                    <div className="m-alert-info__heading-wrap">
                                        <h5 className="m-alert-info__heading">{alertRegion.name}</h5>

                                        {alertRegion.importedAt && (
                                            <p className="m-alert-info__subheading">
                                                Warnung vom {momentFullFormat(alertRegion.importedAt)} Uhr
                                            </p>
                                        )}
                                    </div>
                                ),
                                content: (
                                    <>
                                        <p>Gefährdung: {alertClass?.name}</p>

                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    alertRegion.preAlert &&
                                                    alertClass?.preAlertDescription !== undefined
                                                        ? alertClass.preAlertDescription
                                                        : alertClass?.description,
                                            }}
                                        ></div>

                                        <a className="m-alert-info__link" href={routes.warnings.path}>
                                            Zur Warnungsübersicht
                                        </a>
                                    </>
                                ),
                            }}
                        </EAccordeon>
                    </div>
                </div>
            ) : (
                alertClass && (
                    <EMessage highlightColor={alertClass.color}>
                        <p>
                            Hier geht es zur Übersicht aller Warngebiete, aufgelistet mit der jeweils aktuellen
                            Warnstufe.
                        </p>

                        <a className="m-alert-info__link" href={routes.warnings.path}>
                            Zur Warnungsübersicht
                        </a>
                    </EMessage>
                )
            )}
        </div>
    );
};

export default MAlertInfo;
