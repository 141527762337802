import { Link } from 'react-router-dom';
import IconAlert from '../../../assets/images/icon-alert.svg';
import IconInfo from '../../../assets/images/icon-info.svg';
import routes from '../../../config/routes';
import { IStatusReport } from '../../../models';
import { momentFullFormat } from '../../../service/chartDataHandler';
import { useConfigRequest } from '../../../service/globalService';
import EHelpIcon from '../help-icon/EHelpIcon';
import './EStatusReport.scss';

interface IProps {
    riverAreaId: number | undefined;
    isDetailView: boolean;
    statusReports: IStatusReport[];
}

const EStatusReportList = ({ riverAreaId, isDetailView, statusReports }: IProps) => {
    const { data: config } = useConfigRequest();

    const riverAreaConfig = riverAreaId ? config.riverareas[riverAreaId] : null;
    const areaName = riverAreaConfig?.name || 'Rheinland-Pfalz';
    const isWarning = statusReports.some((report) => report.isInfo === false);
    return (
        <div className={'e-status-report' + (isDetailView ? ' detail' : '')}>
            <div className={'e-status-report__icon' + (isWarning ? ' is-warning' : '')}>
                <img alt={'Hochwasserbericht'} src={isWarning ? IconAlert : IconInfo} />
            </div>
            <div className={'e-status-report__body'}>
                <div className={'e-status-report__items'}>
                    {statusReports.map((statusReport, i) => {
                        const riverArea = config?.riverareas[statusReport.riverAreaId];
                        return (
                            <div className="e-status-report__item" key={i}>
                                <p className="h5">
                                    {statusReports.length > 1 && statusReport.riverParameter
                                        ? riverArea?.rivers[statusReport.riverParameter].name
                                        : areaName}
                                    {i === 0 && <EHelpIcon hash={'bericht'} label="Hinweise zum Hochwasserbericht" />}
                                </p>

                                {statusReport.date && (
                                    <p className="e-status-report__subheadline">
                                        {statusReport.isInfo ? 'Infobericht' : 'Hochwasserbericht'} vom{' '}
                                        {momentFullFormat(statusReport.date)} Uhr
                                    </p>
                                )}

                                {statusReport.info && !isDetailView && (
                                    <div
                                        className="e-status-report__content"
                                        dangerouslySetInnerHTML={{ __html: statusReport.info }}
                                    ></div>
                                )}
                            </div>
                        );
                    })}
                </div>

                <Link className="e-status-report__link" to={routes.statusReport.path + '#' + riverAreaConfig?.slug}>
                    Zum Bericht
                </Link>
            </div>
        </div>
    );
};

export default EStatusReportList;
