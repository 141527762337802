import IconInfo from '../../../assets/images/icon-info.svg';
import { IHint } from '../../../models';
import EMessage from '../../elements/message/EMessage';

const severityMap: Record<IHint['type'], 'info' | 'warning' | 'error'> = {
    info: 'info',
    error: 'error',
    warning: 'warning',
    municipal: 'info',
};

interface IProps {
    hint?: IHint;
    showHelpIcon?: boolean;
}

// TODO Refactor this. This component loosely relies on IHint, what makes it unusable for other purposes.
const MHintMessage = ({ hint, showHelpIcon }: IProps) =>
    hint && (
        <EMessage
            type={severityMap[hint.type]}
            iconUrl={hint?.type === 'municipal' ? IconInfo : undefined}
            helpIcon={showHelpIcon ? { hash: 'hinweise', label: 'Hinweis zur Hochwassersituation' } : undefined}
            showIcon
        >
            {hint.text && <div className="m-hint-message__text" dangerouslySetInnerHTML={{ __html: hint.text }}></div>}
        </EMessage>
    );

export default MHintMessage;
