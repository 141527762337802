import { useEffect } from 'react';
import { useConfigRequest } from '../../../service/globalService';

const MatomoTracker = () => {
    const { data: config } = useConfigRequest();

    useEffect(() => {
        if (config?.settings?.matomoIsActive === '1') {
            const mtm = (window._mtm = window._mtm || []);
            mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
            const d = document,
                g = d.createElement('script'),
                s = d.getElementsByTagName('script')[0];
            g.async = true;
            g.src = config.settings.matomoUrl;
            s.parentNode.insertBefore(g, s);
        }
    }, [config]);

    return null;
};

export default MatomoTracker;
