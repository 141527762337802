import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import { Stack } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IMeasurementSiteConfig } from '../../../models/config';
import { useConfigRequest } from '../../../service/globalService';
import EHelpIcon from '../../elements/help-icon/EHelpIcon';
import './MDetailMenu.scss';

interface IProps {
    riverAreaSlug: string;
    msSlug?: string;
}

const MDetailMenu = (props: IProps) => {
    const [riverAreaId, setRiverAreaId] = useState<number>(null);
    const [riverAreaSlug, setRiverAreaSlug] = useState<string>(props.riverAreaSlug);
    const [riverParameter, setRiverParameter] = useState<string>(null);
    const [msSlug, setMsSlug] = useState(props.msSlug);
    const [type, setType] = useState<string>('municipal');
    const location = useLocation();
    const { data: config } = useConfigRequest();

    useEffect(() => {
        if (config) {
            const measurementsites: IMeasurementSiteConfig[] = Object.values(config.measurementsite);
            const segments = location.pathname.split('/');

            if (
                typeof segments[1] !== undefined &&
                typeof segments[2] !== undefined &&
                (segments[1] === 'kommunale-messstellen' ||
                    (segments[1] === 'pegelliste' && segments[2] === 'kommunal'))
            ) {
                setType('municipal');
            } else if (typeof segments[3] !== undefined) {
                const msSlugTmp = segments[3];
                const typeTmp = measurementsites.filter((measurementsite: IMeasurementSiteConfig) => {
                    return measurementsite.slug === msSlugTmp;
                })[0]?.type;
                setType(typeTmp);
            }
        }
    }, [config, location.pathname]);

    const history = useHistory();

    const changeRiverArea = (event: ChangeEvent<HTMLSelectElement>) => {
        const slug: string = event.target.value;
        const raId = config.slugs[slug].id;
        setRiverAreaId(raId);
        setRiverAreaSlug(slug);
        setRiverParameter(null);
        setMsSlug(null);
    };

    const changeRiver = (event: ChangeEvent<HTMLSelectElement>) => {
        setRiverParameter(event.target.value);
        setMsSlug(null);
    };

    const changeMeasurementSite = (event: ChangeEvent<HTMLSelectElement>) => {
        setMsSlug(event.target.value);
    };

    const openDetail = () => {
        if (msSlug) {
            history.push('/flussgebiet/' + riverAreaSlug + '/' + msSlug);
        } else if (riverParameter) {
            const river = config?.riverareas[riverAreaId].rivers[riverParameter];
            if (!river) {
                console.error('River not found');
                return;
            }
            history.push('/flussgebiet/' + riverAreaSlug + '#' + river.name);
        } else {
            history.push('/flussgebiet/' + riverAreaSlug);
        }
    };

    useEffect(() => {
        // Set active select fields and rivers.
        setRiverAreaSlug(props.riverAreaSlug);
        const slugData = config?.slugs[props.riverAreaSlug];
        setRiverAreaId(slugData?.id);
        setMsSlug(props.msSlug);
        const riverParameterTmp =
            props.msSlug && slugData
                ? config?.measurementsite[slugData.measurementsites[props.msSlug]?.ms_number]?.rivers[0]
                : null;
        setRiverParameter(riverParameterTmp);
    }, [props.riverAreaSlug, props.msSlug, config]);

    return (
        <div className="m-detail-menu l-content-box">
            <Button variant="contained" href={'/'} className="button">
                Zurück zur Kartenansicht
            </Button>
            {type !== 'municipal' && (
                <div>
                    <p className="h4">
                        Datenansicht{' '}
                        <span>
                            wählen
                            <EHelpIcon hash={'navigation'} label="Erläuterungen zur Navigation in der Datenansicht" />
                        </span>
                    </p>

                    <FormControl fullWidth>
                        <InputLabel aria-label="Select Option zum Auswählen der Flussgebiete.">Flussgebiet</InputLabel>
                        <NativeSelect
                            defaultValue={riverAreaSlug}
                            onChange={changeRiverArea}
                            className="m-detail-menu__select"
                        >
                            {config?.riverareas &&
                                Object.entries(config.riverareas)
                                    .filter(([raId]) => raId !== '8888888888')
                                    .map(([, riverArea], i) => (
                                        <option value={riverArea.slug} key={i}>
                                            {riverArea.name}
                                        </option>
                                    ))}
                        </NativeSelect>
                    </FormControl>

                    <FormControl fullWidth>
                        <InputLabel shrink={true} aria-label="Select Option zum Auswählen der Flüsse.">
                            Fluss
                        </InputLabel>
                        <NativeSelect
                            value={riverParameter || ''}
                            onChange={changeRiver}
                            className="m-detail-menu__select"
                        >
                            {!riverParameter && (
                                <option value="" key={-1}>
                                    Fluss auswählen
                                </option>
                            )}
                            {riverAreaId &&
                                Object.keys(config?.riverareas[riverAreaId].rivers).map((riverPara) => {
                                    const river = config?.riverareas[riverAreaId].rivers[riverPara];
                                    return (
                                        <option value={riverPara} key={riverPara}>
                                            {river.name}
                                        </option>
                                    );
                                })}
                        </NativeSelect>
                    </FormControl>

                    <FormControl fullWidth>
                        <InputLabel shrink={true} aria-label="Select Option zum Auswählen der Pegelorte.">
                            Pegelort
                        </InputLabel>
                        <NativeSelect
                            value={msSlug || ''}
                            onChange={changeMeasurementSite}
                            className="m-detail-menu__select"
                        >
                            {!msSlug && (
                                <option value="" key={-1}>
                                    Pegelort auswählen
                                </option>
                            )}
                            {riverAreaId &&
                                riverParameter &&
                                config?.riverareas[riverAreaId].rivers[riverParameter]?.measurementSites.map(
                                    (msNumber) => {
                                        return (
                                            <option value={config?.measurementsite[msNumber].slug} key={msNumber}>
                                                {config?.measurementsite[msNumber].name}
                                            </option>
                                        );
                                    }
                                )}
                        </NativeSelect>
                    </FormControl>

                    <Stack direction="row" justifyContent="flex-end" className="m-detail-menu__submit-wrapper">
                        <Button variant="contained" className="button" onClick={openDetail}>
                            anzeigen
                        </Button>
                    </Stack>
                </div>
            )}
        </div>
    );
};

export default MDetailMenu;
