import { useQueries } from '@tanstack/react-query';
import { Feature, Geometry } from 'geojson';
import L, { CRS, latLng, LeafletEventHandlerFnMap, Map, Path, point } from 'leaflet';
import { Fragment, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MapContainer, MapContainerProps, Pane, useMapEvents } from 'react-leaflet';
import { configRequestQueryOptions, indexRequestQueryOptions } from '../../../service/globalService';
import { getShapeFileData, TShapefileProperties } from '../../../service/shapefileHandler';
import store from '../../../store/store';
import Hatch from '../../../utils/Hatch';
import {
    calcTooltipDirection,
    checkTouchDevice,
    getAlertRegionColor,
    getIsSmallScreenSize,
} from '../../../utils/mapUtils';
import useEventForwarder from '../../../utils/useEventForwarder';
import MContentBox from '../../modules/content-box/MContentBox';
import { MemoizedMDetailbox } from '../../modules/detail-box/MDetailbox';
import MInfobox from '../../modules/info-box/MInfobox';
import './EMap.scss';
import AlertRegionPolygon from './EMap__AlertRegionPolygon';
import { EMap__MemoizedLakePolygon as MemoizedLakePolygon } from './EMap__LakePolygon';
import { EMap__MemoizedMeasurementSiteMarkers as MemoizedMeasurementSiteMarkers } from './EMap__MeasurementSiteMarkers';
import {
    EMap__IMeasurementSitePreview as IMeasurementSitePreview,
    EMap__MeasurementSitePreview as MeasurementSitePreview,
} from './EMap__MeasurementSitePreview';
import NeighbourCountryPolygon from './EMap__NeighbourCountryPolygon';
import PreAlertRegionPolygon from './EMap__PreAlertRegionPolygon';
import { EMap__MemoizedRiverPolygon as MemoizedRiverPolygon } from './EMap__RiverPolygon';

interface IProps {
    children?: ReactNode;
}

const isTouchDevice = checkTouchDevice();

/**
 * Component to register events on leaflet map.
 */
const MapEvents = () => {
    let newMap: Map = undefined;

    const mapEvents: LeafletEventHandlerFnMap = {
        layeradd: (e) => {
            // Set render element clipping tolerance to work around clipped edges issue while panning.
            // Obviously `Path` is the wrong type here, but GeoJSON does not have a Layer type and `Path` fits best.
            const layer = e.layer as Path;
            const layerOptions = layer.options;
            const pane = layerOptions.pane;
            const renderer = newMap.getRenderer(e.layer as L.Path);
            // How much to extend the clipped map area around the viewport in % viewport size. Defaults to .1 = 10%.
            renderer.options.padding = pane === 'regionsdata' /* || pane === 'rivers'*/ ? 1 : 0.33; // Set 100% for alert regions all others 33%
            // How much click tolerance for touch events in pixel.
            renderer.options.tolerance = isTouchDevice ? 8 : 2;
        },

        // resize: () => {
        //     newMap.setView(getIsSmallScreenSize() ? [43.9, 3.45] : [44.4, 3.5], newMap.getZoom())
        // },
    };

    if (!isTouchDevice) {
        mapEvents.tooltipopen = (e) => {
            const direction = calcTooltipDirection(
                newMap.latLngToContainerPoint(e.tooltip.getLatLng()),
                newMap.getContainer().getBoundingClientRect()
            );
            if (e.tooltip.options.direction !== direction) {
                e.tooltip.options.direction = direction;
                e.tooltip.update();
            }
        };
    }

    newMap = useMapEvents(mapEvents);

    return null;
};

// The MapEvents component is used only here and strongly relates to EMap.
// eslint-disable-next-line react/no-multi-comp
const EMap = (props: IProps) => {
    const [{ data: config, isLoading: configIsLoading }, { data: index, isLoading: indexIsLoading }] = useQueries({
        queries: [configRequestQueryOptions, indexRequestQueryOptions],
    });

    const [alertRegionGroups, setAlertRegionGroups] = useState<Feature<Geometry, TShapefileProperties>[][]>();
    const [preAlertRegionGroups, setPreAlertRegionGroups] = useState<Feature<Geometry, TShapefileProperties>[][]>();

    const [riverLines, setRiverLines] = useState<Feature<Geometry, TShapefileProperties>[]>();
    const [lakes, setLakes] = useState<Feature<Geometry, TShapefileProperties>[]>();
    const [neighbourcountries, setNeighbourcountries] = useState<Feature<Geometry, TShapefileProperties>[]>();
    // const [riverAreas, setRiverAreas] = useState([]);

    const [showAlertRegions, setShowAlertRegions] = useState(true);
    const [showRivers, setShowRivers] = useState(true);
    const [showStations, setShowStations] = useState(true);
    const [showMunicipal, setShowMunicipal] = useState(true);

    const [infoboxOpen, setInfoboxOpen] = useState(!getIsSmallScreenSize());
    const [detailboxOpen, setDetailboxOpen] = useState(false);
    const [contentBoxOpen, setContentBoxOpen] = useState(false);

    const map = useRef<L.Map>(null);

    const [mobilePreview, setMobilePreview] = useState<IMeasurementSitePreview | null>(null);

    const { initEventForwarder } = useEventForwarder(100);

    // Calculate combined loading state.
    // IMPORTANT! Because of a known issue from using react-leaflet 3.x together with React 18.x we should prevent any
    // state change (forcing a rerender) during map initialization. Therefore we wait until all state data shown in the
    // map is set.
    const loading = configIsLoading || indexIsLoading || !alertRegionGroups;

    // Get all necessary data nut alert regions as soon as config request is loaded.
    useEffect(() => {
        if (config) {
            const { files } = config;
            void Promise.all([
                getShapeFileData('riverLines', files.gewaesser),
                getShapeFileData('lakes', files.flaechengewaesser),
                getShapeFileData('neighbourcountry', files.nachbarland),
                // getShapeFileData('riverAreas', files.flussgebiete),
            ]).then(([riverLinesCollection, lakesCollection, neighbourcountryCollection]) => {
                // Hide loader as soon as config, index, alert regions and measurement sites are loaded.
                setRiverLines(riverLinesCollection.features);
                setLakes(lakesCollection.features);
                setNeighbourcountries(neighbourcountryCollection.features);
            });
        }
    }, [config]);

    // Get alert regions as soon as config and index request are loaded.
    useEffect(() => {
        if (config && index) {
            const { files } = config;
            void getShapeFileData('alertRegions', files.warnregionen).then((alertRegionsCollection) => {
                // Group alert regions by `TYPE`.
                const alertRegionsTmp: Feature<Geometry, TShapefileProperties>[][] = [];
                const preAlertRegionsTmp: Feature<Geometry, TShapefileProperties>[][] = [];

                alertRegionsCollection.features.forEach((data) => {
                    const properties = data.properties;
                    const type = properties.TYPE || 0;

                    if (index.alertregions[properties.ID]?.preAlert) {
                        const group = preAlertRegionsTmp[type] || [];
                        group.push(data);
                        preAlertRegionsTmp[type] = group;
                        return;
                    }

                    const group = alertRegionsTmp[type] || [];
                    group.push(data);
                    alertRegionsTmp[type] = group;
                });

                // Sort groups by `DRAW_ORDER`.
                alertRegionsTmp.forEach((group) => {
                    group.sort((a, b) => (a.properties.DRAW_ORDER || 0) - (b.properties.DRAW_ORDER || 0));
                });

                preAlertRegionsTmp.forEach((group) => {
                    group.sort((a, b) => (a.properties.DRAW_ORDER || 0) - (b.properties.DRAW_ORDER || 0));
                });

                setAlertRegionGroups(alertRegionsTmp);
                setPreAlertRegionGroups(preAlertRegionsTmp);
            });
        }
    }, [config, index]);

    /**
     * Determine viewport center based on current and given new overlay boxes open states.
     */
    const getViewportCenter = useCallback(
        (newState?: { infoboxOpen: boolean; detailboxOpen: boolean; contentBoxOpen: boolean }, center?: L.LatLng) => {
            if (!newState) {
                newState = { infoboxOpen, detailboxOpen, contentBoxOpen };
            }

            const customCenter: boolean = !!center;
            if (!center) {
                // Use current map center or center of bounding box of Rhineland Palatine, if map is not initialized yet.
                // Hint: The "real" center of RLP is 49.913056, 7.45 but we need a little offset to fit map overlay boxes.
                center = map.current?.getCenter() || latLng(49.913056, 6.9);
            }

            // Short path for small screens where center does not change when toggling overlay boxes.
            if (getIsSmallScreenSize()) {
                return center;
            }

            // Calculate current content width.
            const vpWidth = window.innerWidth;
            const vpHeight = document.getElementsByClassName('e-map')[0]?.getBoundingClientRect().height || 0;
            const contentWidth = Math.min(vpWidth, 1200) - 28; // Minus padding
            // Restrict viewport width to 2x content width.
            const maxVpWidth = Math.min(vpWidth, contentWidth * 2);
            const deltaVpWidth = vpWidth - maxVpWidth;
            // Calculate relevant measures of info and detail boxes instead of getting size from dom because at current time they may be collapsed and its size will change during upcoming expansion.
            const infoBoxRight = (vpWidth - contentWidth) / 2 + contentWidth * 0.33;
            const detailBoxLeft = (vpWidth - contentWidth) / 2 + contentWidth * (1 - 0.6);

            const zoom = getIsSmallScreenSize() ? 8 : 9;

            let p: L.Point;
            // Check if map has already been initialized.
            if (map.current) {
                p = map.current.latLngToContainerPoint(center);

                // Reset center to collapsed boxes.
                if (infoboxOpen) {
                    p = p.add([(infoBoxRight - deltaVpWidth) / 2, 0]);
                }
                if (detailboxOpen) {
                    p = p.add([-(detailBoxLeft - deltaVpWidth) / 2, 0]);
                }
            } else {
                // Because map is not initialized yet, we have to do coordinate to pixel conversion ourselves.
                const pCenter = CRS.EPSG3857.latLngToPoint(center, zoom).round();
                const viewHalf = point(vpWidth / 2, vpHeight / 2);
                const pixelOrigin = pCenter.subtract(viewHalf);
                p = pCenter.subtract(pixelOrigin);
            }

            // Initially offset center to not overlap content box preview.
            if (!map.current || customCenter) {
                p = p.add(point(0, (100 / 2) * (customCenter ? -1 : 1)));
            }

            // Transition center to new collapsing state of boxes.
            if (newState.infoboxOpen) {
                p = p.add([-(infoBoxRight - deltaVpWidth) / 2, 0]);
            }
            if (newState.detailboxOpen) {
                p = p.add([(detailBoxLeft - deltaVpWidth) / 2, 0]);
            }

            if (map.current) {
                center = map.current.containerPointToLatLng(p);
            } else {
                // Because map is not initialized yet, we have to do pixel to coordinate conversion ourselves.
                const pCenter = CRS.EPSG3857.latLngToPoint(center, zoom).round();
                const viewHalf = point(vpWidth / 2, vpHeight / 2);
                const pixelOrigin = pCenter.subtract(viewHalf);
                p = p.add(pixelOrigin);
                center = CRS.EPSG3857.pointToLatLng(p, zoom);
            }

            return center;
        },
        [infoboxOpen, detailboxOpen, contentBoxOpen, map]
    );

    // /**
    //  * Fit viewport to given bounds respecting overlay box state.
    //  */
    // const fitBounds = useCallback(
    //   (bounds: L.LatLngBounds) => {
    //     if (!map.current) {
    //       return;
    //     }

    //     const m = map.current as any;
    //     const target = m._getBoundsCenterZoom(bounds, {
    //       padding: getIsSmallScreenSize() ? [0, 0] : [150, 150],
    //     });

    //     const center: L.LatLng = m.getCenter();
    //     const zoom = m.getZoom();

    //     // Set map zoom to fitted bounds.
    //     m.setView(center, target.zoom, true);
    //     const newCenter = getViewportCenter(
    //       { infoboxOpen: false, detailboxOpen: false, contentBoxOpen: false },
    //       center
    //     );
    //     const [deltaLat, deltaLng] = [
    //       center.lat - newCenter.lat,
    //       center.lng - newCenter.lng,
    //     ];

    //     target.center.lat += deltaLat;
    //     target.center.lng += deltaLng;

    //     // Reset map zoom to start.
    //     m.setView(center, zoom, true);

    //     m.setView(target.center, target.zoom);
    //   },
    //   [getViewportCenter]
    // );

    const toggleInfoBox = useCallback(
        (isOpen: boolean) => {
            setInfoboxOpen(isOpen);
            if (isOpen) {
                setDetailboxOpen(false);
                setContentBoxOpen(false);
            }
            // Offset map so that box does not overlap current map center.
            if (map.current) {
                setTimeout(() => {
                    map.current.setView(
                        getViewportCenter({
                            infoboxOpen: isOpen,
                            detailboxOpen: false,
                            contentBoxOpen: false,
                        })
                    );
                }, 200);
            }
        },
        [getViewportCenter]
    );

    const toggleDetailBox = useCallback(
        (isOpen: boolean) => {
            setDetailboxOpen(isOpen);
            if (isOpen) {
                setInfoboxOpen(false);
                setContentBoxOpen(false);
            }
            // Offset map so that box does not overlap current map center.
            if (map.current) {
                setTimeout(() => {
                    map.current.setView(
                        getViewportCenter({
                            infoboxOpen: false,
                            detailboxOpen: isOpen,
                            contentBoxOpen: false,
                        })
                    );
                }, 200);
            }
        },
        [getViewportCenter]
    );

    const toggleContentBox = useCallback(
        (isOpen: boolean) => {
            setContentBoxOpen(isOpen);
            setInfoboxOpen(!getIsSmallScreenSize() && !isOpen);
            if (isOpen) {
                setDetailboxOpen(false);
            }
            // Offset map so that box does not overlap current map center.
            if (map.current) {
                setTimeout(() => {
                    map.current.setView(
                        getViewportCenter({
                            infoboxOpen: !getIsSmallScreenSize() && !isOpen,
                            detailboxOpen: false,
                            contentBoxOpen: isOpen,
                        })
                    );
                }, 200);
            }
        },
        [getViewportCenter]
    );

    // Initial map settings.
    const mapConfig = useMemo<MapContainerProps>(
        () => ({
            // Restricting bounds badly influences dynamic map repositioning when toggling overlay boxes. Additionally having this setting disabled seems to speed up map rendering performance.
            //maxBounds: L.latLngBounds(L.latLng(40.4, -2), L.latLng(47.4, 9)),
            center: getViewportCenter(),
            zoom: getIsSmallScreenSize() ? 7.5 : 8.5,
            zoomSnap: 0.25,
            minZoom: 7.5,
            maxZoom: 11.5,
            touchZoom: true,
            doubleClickZoom: false,
            // scrollWheelZoom: false

            // For large datasets this drastically speeds up map performance.
            preferCanvas: true,
            // This is not working as expected and we have to set these option in addlayer event.
            // renderer: L.canvas({
            //     padding: .66,
            //     // tolerance: 10
            // }),
        }),
        [getViewportCenter]
    );

    return (
        <div className={'e-map' + (loading ? ' e-map--loading' : '')} data-testid="map">
            {!loading && (
                <>
                    <div className="e-map__overlay container">
                        <MInfobox
                            config={config}
                            showAlertRegions={showAlertRegions}
                            showRivers={showRivers}
                            showStations={showStations}
                            showMunicipal={showMunicipal}
                            infoboxOpen={infoboxOpen}
                            setShowAlertRegions={setShowAlertRegions}
                            setShowRivers={setShowRivers}
                            setShowStations={setShowStations}
                            setShowMunicipal={setShowMunicipal}
                            setInfoboxOpen={toggleInfoBox}
                        />

                        <MemoizedMDetailbox
                            config={config}
                            index={index}
                            detailboxOpen={detailboxOpen}
                            setDetailboxOpen={toggleDetailBox}
                        />
                    </div>

                    <MContentBox isOpen={contentBoxOpen} setIsOpen={toggleContentBox}>
                        {props.children}
                    </MContentBox>

                    <div className="e-map__hatches-prerender">
                        {config.alertclasses &&
                            Object.keys(config.alertclasses).map((id) => {
                                const alertClass = config.alertclasses[id];
                                return (
                                    <Hatch
                                        color={alertClass.color}
                                        stripeColor={alertClass.stripeColor}
                                        alertClassId={id}
                                        key={id}
                                    />
                                );
                            })}
                    </div>

                    <MapContainer
                        {...mapConfig}
                        // Store reference to leaflet instance.
                        ref={(m) => {
                            map.current = m;

                            // Fix lag on first zoom.
                            // TODO 2024-08-01 This is not working any longer (Windows 11 Pro, Chrome 126.0.6478.127).
                            // map.current.zoomIn(0.1, { animate: false });
                            // setTimeout(() => {
                            //     map.current.zoomOut(0.1, { animate: false });
                            // }, 0);

                            initEventForwarder(m);
                        }}
                        className="e-map__map"
                        data-testid="map-container"
                    >
                        <MapEvents />

                        {/* DEBUG Show open street map layer to control coordinate projection */}
                        {/* <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            maxZoom={19}
                            attribution="© OpenStreetMap"
                        /> */}

                        <Pane name="neighbourcountries" style={{ zIndex: 399 }}>
                            {neighbourcountries.map((neighbourCountry, i) => {
                                if (neighbourCountry.properties.NAME !== 'Rheinland-Pfalz') {
                                    return (
                                        <NeighbourCountryPolygon
                                            data={neighbourCountry}
                                            name={neighbourCountry.properties.NAME}
                                            url={neighbourCountry.properties.URL}
                                            key={i}
                                        />
                                    );
                                }
                                return null;
                            })}
                        </Pane>

                        {alertRegionGroups.map((regions, i) => (
                            <Fragment key={`alert_region_fragment_${i}`}>
                                <Pane
                                    name={`regionsdata_${i}`}
                                    // Render regions of higher "type" (here group index) always above lower "type" regions.
                                    style={{ zIndex: 400 + i * 2 }}
                                    key={`alert_${i}`}
                                >
                                    {regions.map((data, j) => (
                                        <AlertRegionPolygon
                                            data={data}
                                            key={`${j}_${showAlertRegions}`}
                                            fillColor={
                                                showAlertRegions
                                                    ? getAlertRegionColor(data.properties.ID, config, index)
                                                    : undefined
                                            }
                                            onClick={
                                                showAlertRegions
                                                    ? () => {
                                                          store.dispatch.selectedAlertRegion.setId(data.properties.ID);
                                                          toggleDetailBox(true);
                                                      }
                                                    : undefined
                                            }
                                        />
                                    ))}
                                </Pane>

                                {preAlertRegionGroups[i]?.length && (
                                    <Pane
                                        name={`regionsdata_2_${i}`}
                                        // Render regions of higher "type" (here group index) always above lower "type" regions.
                                        style={{ zIndex: 401 + i * 2 }}
                                        key={`preAlert_${i}`}
                                    >
                                        {preAlertRegionGroups[i].map((data, j) => (
                                            <PreAlertRegionPolygon
                                                data={data}
                                                key={`${j}_${showAlertRegions}`}
                                                pane={`regionsdata_2_${i}`}
                                                fillColor={
                                                    showAlertRegions
                                                        ? getAlertRegionColor(data.properties.ID, config, index, true)
                                                        : undefined
                                                }
                                                onClick={
                                                    showAlertRegions
                                                        ? () => {
                                                              store.dispatch.selectedAlertRegion.setId(
                                                                  data.properties.ID
                                                              );
                                                              toggleDetailBox(true);
                                                          }
                                                        : undefined
                                                }
                                            />
                                        ))}
                                    </Pane>
                                )}
                            </Fragment>
                        ))}

                        <Pane name="lakes" style={{ zIndex: 405, pointerEvents: 'none' }}>
                            {showRivers && lakes && lakes.map((data, i) => <MemoizedLakePolygon data={data} key={i} />)}
                        </Pane>

                        <Pane name="rivers" style={{ zIndex: 406, pointerEvents: 'none' }}>
                            {showRivers && riverLines.map((data, i) => <MemoizedRiverPolygon data={data} key={i} />)}
                        </Pane>

                        <Pane name="stations" style={{ zIndex: 407 }}>
                            {showStations && (
                                <MemoizedMeasurementSiteMarkers
                                    config={config}
                                    index={index}
                                    setMobilePreview={setMobilePreview}
                                    showMunicipal={showMunicipal}
                                />
                            )}
                        </Pane>
                    </MapContainer>

                    {mobilePreview && <MeasurementSitePreview {...mobilePreview} setMobilePreview={setMobilePreview} />}
                </>
            )}
        </div>
    );
};

export default EMap;
