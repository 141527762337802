import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import moment from 'moment-timezone';
import { IWaterLevel } from '../../../models/misc';
import { formatNumber, momentFormatDate, momentFormatHour, momentParse } from '../../../service/chartDataHandler';

/**
 * Group data by date.
 */
const groupByDays = (data: IWaterLevel[]): Array<{ dayName: string; data: IWaterLevel[] }> => {
    const dayNames: string[] = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];

    const result: Record<string, { dayName: string; data: IWaterLevel[] }> = {};
    data.forEach((waterLevel: IWaterLevel) => {
        const m: moment.Moment = momentParse(waterLevel.x);
        const date: string = m.format('YYYY-MM-DD');

        if (!result[date]) {
            result[date] = {
                dayName: dayNames[m.weekday()],
                data: [],
            };
        }
        result[date].data.push(waterLevel);
    });

    return Object.values(result);
};

interface IDataRowProps {
    data: IWaterLevel[];
    unit: string;
}

const DataRows = (props: IDataRowProps) => {
    const groupedData = groupByDays(props.data);
    return (
        <>
            {groupedData.map((day, i) => {
                const last: IWaterLevel = day.data[day.data.length - 1];
                return (
                    <TableRow key={i}>
                        <TableCell>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    <div className="m-detail-measurementsites__table-cell">{day.dayName}</div>
                                    <div className="m-detail-measurementsites__table-cell">
                                        {momentFormatDate(last.x)}
                                    </div>
                                    <div className="m-detail-measurementsites__table-cell"></div>
                                </AccordionSummary>

                                <AccordionDetails>
                                    {day.data.map((data, j) => (
                                        <div className="m-detail-measurementsites__table-row__details" key={j}>
                                            <div className="m-detail-measurementsites__table-cell">{day.dayName}</div>
                                            <div className="m-detal-measurementsites__table-cell">
                                                {momentFormatHour(data.x)} Uhr
                                            </div>
                                            <div className="m-detail-measurementsites__table-cell">
                                                <strong>
                                                    {data.y === null ? '-' : formatNumber(data.y) + ' ' + props.unit}
                                                </strong>
                                            </div>
                                        </div>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        </TableCell>
                    </TableRow>
                );
            })}
        </>
    );
};

interface IProps {
    data: IWaterLevel[] | null;
    predictionData: IWaterLevel[] | null;
    waterLevel: boolean;
    isSeaSite?: boolean;
    children?: React.ReactElement;
}

const EDetailTable = (props: IProps) => {
    const unit: string = props.waterLevel ? (props.isSeaSite ? 'm ü. NHN' : 'cm') : 'm\u00B3/s';
    return (
        <>
            {props.data?.length ? (
                <div className="e-detail-table">
                    <TableContainer className={'m-detail-measurementsites__table-container'}>
                        <Table className={'m-detail-measurementsites__table-wrapper'}>
                            <TableHead className="m-detail-measurementsites__table-head">
                                <TableRow className="m-detail-measurementsites__table-row">
                                    <TableCell className="m-detail-measurementsites__table-cell__head">
                                        Wochentag
                                    </TableCell>
                                    <TableCell className="m-detail-measurementsites__table-cell__head">
                                        Datum/Uhrzeit
                                    </TableCell>
                                    <TableCell className="m-detail-measurementsites__table-cell__head">
                                        Letzter Messwert
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody className="m-detail-measurementsites__table-body">
                                <DataRows data={props.data} unit={unit} />

                                {props.predictionData?.length ? (
                                    <>
                                        <TableRow>
                                            <TableCell>
                                                <p className="m-detail-measurementsites__prediction-lable">
                                                    <strong>Vorhersagen</strong>
                                                </p>
                                            </TableCell>
                                        </TableRow>

                                        <DataRows data={props.predictionData} unit={unit} />
                                    </>
                                ) : (
                                    <TableRow>
                                        <TableCell>
                                            <p className="m-detail-measurementsites__prediction-lable">
                                                <strong>Keine Vorhersagen verfügbar</strong>
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {props.children}
                </div>
            ) : (
                <h3>Keine Daten verfügbar</h3>
            )}
        </>
    );
};

export default EDetailTable;
