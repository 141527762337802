import { IOperator } from '../../../models/config';
import { IMeasurements } from '../../../models/misc';
import { formatNumber, momentFullFormat } from '../../../service/chartDataHandler';
import EHelpIcon from '../../elements/help-icon/EHelpIcon';
import './MDetailMeasurementSites.scss';

interface IProps {
    msData: IMeasurements | null;
    operator: IOperator;
    legendInfo: Record<string, string>;
    isSeaSite?: boolean | null;
    isWaterLevel: boolean;
}

const MDetailMeasurementSitesMetaData = (metaDataProps: IProps) => {
    const operatorName = metaDataProps.operator?.op_name || '';
    const operatorUrl = metaDataProps.operator?.ms_url || metaDataProps.operator?.op_url || '';

    const xLast = metaDataProps.msData?.xLast;
    const yLast = metaDataProps.msData?.yLast;

    const waterLevelInfo = metaDataProps.msData?.measurements;

    return (
        <div className="m-detail-measurementsites__meta-data">
            <div className="m-detail-measurementsites__meta-data__info">
                {(waterLevelInfo?.length || null) && (
                    <>
                        {xLast /*yLast &&*/ && (
                            <p className="m-detail-measurementsites__meta-data__info__last-measurement">
                                Letzter Messwert:{' '}
                                <strong>
                                    {momentFullFormat(xLast)} Uhr,{' '}
                                    {formatNumber(yLast) +
                                        (metaDataProps.isWaterLevel
                                            ? metaDataProps.isSeaSite
                                                ? ' m ü. NHN'
                                                : ' cm'
                                            : ' m\u00B3/s')}
                                </strong>
                            </p>
                        )}

                        {metaDataProps.legendInfo && (
                            <div className="m-detail-measurementsites__meta-data__info__legend">
                                {(metaDataProps.legendInfo.legendText || metaDataProps.legendInfo.forecastTime) && (
                                    <p className="m-detail-measurementsites__meta-data__info__legend--text">
                                        {metaDataProps.legendInfo.legendText
                                            ? metaDataProps.legendInfo.legendText
                                            : 'Vorhersage'}
                                        {metaDataProps.legendInfo.forecastTime && (
                                            <>
                                                {' '}
                                                vom{' '}
                                                <strong>
                                                    {momentFullFormat(metaDataProps.legendInfo.forecastTime)} Uhr
                                                </strong>
                                            </>
                                        )}
                                    </p>
                                )}

                                {metaDataProps.legendInfo.legendText && metaDataProps.legendInfo.legendURL && (
                                    <span>&#8594;</span>
                                )}

                                {metaDataProps.legendInfo.legendURL && (
                                    <a
                                        className="m-detail-measurementsites__meta-data__info__legend--url"
                                        href={metaDataProps.legendInfo.legendURL}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Weitere Infos zur Vorhersage
                                    </a>
                                )}
                            </div>
                        )}
                    </>
                )}
            </div>

            {(operatorUrl || operatorName) && (
                <div className="m-detail-measurementsites__meta-data__operator">
                    <p className="m-detail-measurementsites__meta-data__operator--heading">
                        Betreiber der Messstelle:
                        <EHelpIcon hash={'pegel-metadaten'} label="Hinweise zu den Pegel Metadaten" />
                    </p>

                    {operatorUrl ? (
                        <a
                            className="m-detail-measurementsites__meta-data__operator--url"
                            href={operatorUrl}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {operatorName}
                        </a>
                    ) : (
                        <p className="m-detail-measurementsites__meta-data__operator--name">{operatorName}</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default MDetailMeasurementSitesMetaData;
