/**
 * promise to resolve after given ms
 * @param ms
 */
export const delay = (ms: number) =>
    new Promise<void>((res) => {
        setTimeout(() => {
            res();
        }, ms);
    });

/**
 * Get slug for given string. Converts german umlauts to oe, ue, äe, ...
 *
 * @param {string | null} s
 * @return {string | null}
 */
export const slug = (s: string | null): string | null => {
    if (!s || !s.length) {
        return null;
    }
    const umlautMap = {
        '\u00fc': 'ue',
        '\u00e4': 'ae',
        '\u00f6': 'oe',
        '\u00df': 'ss',
    };
    return (
        s
            .toLowerCase()
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .replace(new RegExp('[' + Object.keys(umlautMap).join('|') + ']', 'g'), (a) => umlautMap[a])
            .replaceAll(/[^A-Za-z0-9]+/g, '-')
            .replace(/^-+/, '')
            .replace(/-+$/, '')
    );
};

/**
 * Pinched from underscore.js
 * Throttle a function call to a maximum of once per wait milliseconds.
 *
 * @param func
 * @param wait
 * @param options
 * @returns {Function}
 * @private
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const throttle = <T extends Function>(
    func: T,
    wait: number,
    options: { leading?: boolean; trailing?: boolean } = { leading: true, trailing: false }
): T => {
    let context, args, result;
    let timeout = null;
    let previous = 0;
    const later = function () {
        previous = options.leading === false ? 0 : Date.now();
        timeout = null;
        // eslint-disable-next-line
        result = func.apply(context, args);
        // eslint-disable-next-line
        if (!timeout) context = args = null;
    };
    // eslint-disable-next-line
    return function (event) {
        // eslint-disable-next-line
        if (!event.originalEvent.isTrusted) {
            // eslint-disable-next-line
            func.apply(this, arguments);
            return;
        }
        const now = Date.now();
        // eslint-disable-next-line
        if (!previous && options.leading === false) previous = now;
        const remaining = wait - (now - previous);
        // eslint-disable-next-line
        context = this;
        // eslint-disable-next-line
        args = arguments;
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                // eslint-disable-next-line
                clearTimeout(timeout);
                timeout = null;
            }
            previous = now;
            // eslint-disable-next-line
            result = func.apply(context, args);
            // eslint-disable-next-line
            if (!timeout) context = args = null;
        } else if (!timeout && options.trailing !== false) {
            timeout = setTimeout(later, remaining);
        }
        // eslint-disable-next-line
        return result;
    } as unknown as T;
};
