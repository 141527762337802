interface IProps {
    color: string;
    stripeColor: string;
    alertClassId: string;
}

/**
 * Used to create a diagonal hatch pattern as fill pattern for svg path on map polygon
 */
const Hatch = (props: IProps) => {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10">
            <defs>
                <pattern
                    id={`Hatch_${props.alertClassId}`}
                    x="0"
                    y="0"
                    width="1"
                    height="10"
                    patternUnits="userSpaceOnUse"
                    patternTransform="rotate(-45)"
                >
                    <rect width="10" height="10" fill={props.color}></rect>
                    <circle cx="1" cy="1" r="1.2" style={{ stroke: 'none', fill: props.stripeColor }} />
                </pattern>
            </defs>
        </svg>
    );
};

export default Hatch;
