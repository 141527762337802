import { useEffect, Fragment, PropsWithChildren } from 'react';
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom';

interface LScrollToTopProps extends PropsWithChildren, RouteComponentProps {}

/**
 * This component ensures, that when rendered the window scroll position is set to 0.
 */
const LScrollToTop = withRouter(({ history, children }: LScrollToTopProps) => {
    const currentLocation = useLocation();

    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            // Scroll to top if...
            if (
                // Not browser history back is used
                action !== 'POP' &&
                // Only hash is changed (URL path equals current path)
                location.pathname !== currentLocation.pathname
            ) {
                window.scrollTo(0, 0);
            }
        });
        return () => {
            unlisten();
        };
    }, [currentLocation.pathname, history]);

    return <Fragment>{children}</Fragment>;
});

export default LScrollToTop;
