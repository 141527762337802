import { useEffect, useState } from 'react';
import IIndex from '../../../models';
import IConfig, { IRiverArea } from '../../../models/config';
import { delay } from '../../../utils/helpers';
import ErrorBoundary from '../../elements/error-boundary/EErrorBoundary';
import MDetailChart from '../detail-chart/MDetailChart';

interface IProps {
    index?: IIndex;
    config: IConfig;
    riverAreaId: number;
}

// Project approved
// eslint-disable-next-line @typescript-eslint/naming-convention
const MDetailboxWaterlevels__AsyncHelper = (props: IProps) => {
    /**
     * LazyloadCount used to lazyload MDetailChart Components asynchroniously
     */
    const [lazyLoadCount, setLazyLoadCount] = useState(1);

    const sortedMeasurementSites: number[] = props.config.measurementsitesorted[props.riverAreaId];
    const riverArea: IRiverArea = props.config.riverareas[props.riverAreaId];

    /**
     * Increase lazyloadCount in steps to determine async render in map render method
     */
    useEffect(() => {
        let mounted = true;
        if (sortedMeasurementSites) {
            void (async () => {
                setLazyLoadCount(1);
                for (let i = 1; i < sortedMeasurementSites.length + 4; i += 4) {
                    if (!mounted) {
                        break;
                    } else {
                        await delay(100);
                        // Recheck mounting state because we may have been unmounted during await.
                        if (mounted) {
                            setLazyLoadCount(i);
                        }
                    }
                }
            })();
        }
        return () => {
            // cleanup function to break the loop when components get unmounted
            mounted = false;
        };
    }, [sortedMeasurementSites]);

    return (
        <>
            {sortedMeasurementSites.map((msNumber, index) => {
                const msData = props.index?.measurementSites[msNumber];
                const msConfig = props.config.measurementsite[msNumber];

                if (msData && index < lazyLoadCount) {
                    return (
                        <ErrorBoundary key={index} errorMsg={'Pegel konnte nicht geladen werden'}>
                            <MDetailChart
                                msName={msConfig.name}
                                data={msData}
                                key={index}
                                msSlug={msConfig.slug}
                                riverName={props.config.rivers[msConfig.rivers[0]]?.name}
                                riverAreaSlug={riverArea.slug}
                                isSeaSite={msConfig.isSeaSite}
                            />
                        </ErrorBoundary>
                    );
                }
                return null;
            })}
        </>
    );
};

export default MDetailboxWaterlevels__AsyncHelper;
