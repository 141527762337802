import { ILegend } from '../../../models/config';
import { IExtremeEventsData } from '../../../models/misc';
import EEventBarChart from '../../elements/event-bar-chart/EEventBarChart';
import EHelpIcon from '../../elements/help-icon/EHelpIcon';

interface IProps {
    extremeEvents: null | Array<IExtremeEventsData>;
    legends: Record<string, ILegend>;
    threshold: Record<string, number>;
}
const MWaterEvents = (props: IProps) => {
    return (
        <div className="m-water-events">
            <h2>
                Hochwasserereignisse
                <EHelpIcon hash={'hochwasserereignisse'} label="Hinweise zu den Hochwasserereignissen" />
            </h2>

            <EEventBarChart
                extremeEventsData={props.extremeEvents}
                legends={props.legends}
                threshold={props.threshold}
            ></EEventBarChart>
        </div>
    );
};

export default MWaterEvents;
