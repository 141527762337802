import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { INotify, IState } from '../../../store/store';

const ESnackbar = () => {
    const notify = useStoreState((state: IState) => state.notify.item);

    const { set: clearNotify } = useStoreActions((actions: IState) => actions.notify);

    const notifyObj: INotify = {
        shown: false,
        message: '',
        severity: 'error',
    };

    const handleClick = () => {
        // @ts-expect-error Wrong types here. This is callable.
        clearNotify(notifyObj);
    };

    if (notify.message) {
        return (
            <Snackbar data-testid="snackbar" open={notify.shown} autoHideDuration={6000}>
                <MuiAlert
                    data-testid="snackbar-alert"
                    elevation={6}
                    severity={notify.severity}
                    variant="filled"
                    onClose={handleClick}
                >
                    {notify.message}
                </MuiAlert>
            </Snackbar>
        );
    }

    return null;
};

export default ESnackbar;
