import { memo } from 'react';
import AlertIcon from '../../../assets/images/icon-alert.svg';
import InfoIcon from '../../../assets/images/icon-info.svg';
import IConfig from '../../../models/config';
import IIndex from '../../../models/index';
import store from '../../../store/store';
import ErrorBoundary from '../../elements/error-boundary/EErrorBoundary';
import EHelpIcon from '../../elements/help-icon/EHelpIcon';
import EStatusReport from '../../elements/status-report/EStatusReport';
import MAlertInfo from '../alert-info/MAlertInfo';
import MDetailboxWaterlevels from '../detail-box-waterlevels/MDetailboxWaterlevels';
import MHintMessage from '../hint-message/MHintMessage';
import './MDetailbox.scss';

interface IProps {
    config: IConfig;
    index: IIndex | null;
    detailboxOpen: boolean;
    setDetailboxOpen: (isOpen: boolean) => void;
}

const MDetailbox = ({ config, detailboxOpen, index, setDetailboxOpen }: IProps) => {
    const toggleOpenState = () => {
        if (detailboxOpen) {
            // called when the box is open, means it gets closed
            // reset the active alertregion
            store.dispatch.selectedAlertRegion.setId(undefined);
        }
        setDetailboxOpen(!detailboxOpen);
    };

    return (
        <div className={'m-detailbox' + (detailboxOpen ? ' m-detailbox--open' : '')}>
            <div className="m-detailbox__head">
                <h3 className="m-detailbox__heading">
                    Aktueller Bericht
                    <EHelpIcon hash={'bericht'} label="Erläuterungen zum aktuellen Bericht" />
                </h3>
                <div
                    className="m-detailbox__toggle-button"
                    tabIndex={1}
                    onClick={toggleOpenState}
                    onKeyPress={toggleOpenState}
                >
                    <span className={'m-detailbox__toggle-button__inner'}></span>
                    <img
                        alt={'Info Icon'}
                        aria-label="Klicke zum Aufklappen"
                        src={index?.statusReport?.isInfo ? InfoIcon : AlertIcon}
                        className={'m-detailbox__toggle-button__info-icon'}
                    />
                </div>
            </div>

            <div className="m-detailbox__body">
                {/*<h4 className="m-detailbox__accordion__heading">*/}
                {/*  Allgemeine Hinweise*/}
                {/*</h4>*/}
                {index?.statusReport && (
                    <div className="m-detailbox__status-report">
                        <ErrorBoundary errorMsg={'Hochwasserbericht konnte nicht geladen werden'}>
                            <EStatusReport statusReport={index.statusReport} isDetailView={false} />
                        </ErrorBoundary>
                    </div>
                )}
                <div className="m-detailbox__message__head">
                    <h3 className="m-detailbox__message__heading">
                        Aktuelle Warnung
                        <EHelpIcon hash={'warnungen'} label="Erläuterungen zu den Warnungen" />
                    </h3>
                </div>
                {index && <MAlertInfo index={index} config={config} />}
                {index?.hint && (
                    <div className="m-detailbox__message">
                        <ErrorBoundary errorMsg={'Hinweise sind nicht Verfügbar'}>
                            <MHintMessage hint={index.hint} showHelpIcon />
                        </ErrorBoundary>
                    </div>
                )}
                <div className="m-detailbox__content">
                    <h3>Pegelstände</h3>
                    {config && detailboxOpen && (
                        <ErrorBoundary errorMsg={'Details konnten nicht geladen werden'}>
                            <MDetailboxWaterlevels index={index} config={config} />
                        </ErrorBoundary>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MDetailbox;

export const MemoizedMDetailbox = memo(MDetailbox);
