import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Tab, Tabs } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import routes from '../../../config/routes';
import VStaticPage from '../static-page/VStaticPage';
import './VRainfallMaps.scss';

const VRainfallMaps = () => {
    const tabs = ['rlp', 'mosel'];
    const files = {
        rlp: 'niederschlagskarte',
        mosel: 'niederschlagskarte-mosel',
    };
    const hash = useLocation().hash;
    const history = useHistory();

    // Determine initial tab from location hash.
    const [tabValue, setTabValue] = useState(
        (hash !== '' && tabs.find((name) => routes['rainfall-' + name]?.path === routes.rainfall.path + hash)) ||
            tabs[0]
    );

    return (
        <div className="v-rainfall-maps container">
            <TabContext value={tabValue}>
                <Box className="v-rainfall-maps__navigation">
                    <Tabs value={tabValue} onChange={(_, name: string) => setTabValue(name)}>
                        {tabs.map((name, i) => (
                            <Tab
                                className="v-rainfall-maps__tab-button"
                                label={routes['rainfall-' + name].label}
                                value={name}
                                key={i}
                                onClick={() => {
                                    history.push('/niederschlagskarte#' + name);
                                }}
                            />
                        ))}
                    </Tabs>
                </Box>

                {tabs.map((name, i) => (
                    <TabPanel className="v-rainfall-maps__tabpanel" value={name} key={i}>
                        <VStaticPage route={'/' + files[name]} path={'/static/shared/map'} />
                    </TabPanel>
                ))}
            </TabContext>
        </div>
    );
};

export default VRainfallMaps;
