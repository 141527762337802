import { Feature, Geometry } from 'geojson';
import { MarkerOptions, PolylineOptions } from 'leaflet';
import { memo } from 'react';
import { GeoJSON, GeoJSONProps } from 'react-leaflet';
import { TShapefileProperties } from '../../../service/shapefileHandler';
import { unproject } from '../../../utils/mapUtils';
import './EMap__Polygon.scss';

/**
 * Polygon Component Leaflet Layer
 * used to display and handle lakes
 */
interface IProps {
    data: Feature<Geometry, TShapefileProperties>;
}

// Project approved
// eslint-disable-next-line @typescript-eslint/naming-convention
const EMap__LakePolygon = ({ data }: IProps) => {
    /**
     * IMPORTANT! NO STATES HERE!!!
     * It badly influences map rendering performance. Scaling of points is ONLY
     * done using map events and native leaflet API.
     */

    const opts: GeoJSONProps & PolylineOptions & MarkerOptions = {
        data,
        coordsToLatLng: unproject,
        // weight: .5,
        noClip: true,
        shadowPane: '',
        interactive: false,
        className: 'polygon',
        style: {
            fillColor: '#5c7ea2',
            color: 'blue',
            fillOpacity: 1,
            weight: 0,
        },
    };

    return <GeoJSON {...opts} />;
};

export default EMap__LakePolygon;
// Project approved
// eslint-disable-next-line @typescript-eslint/naming-convention
export const EMap__MemoizedLakePolygon = memo(EMap__LakePolygon);
