import { TableCell, TableRow } from '@material-ui/core';
import { forwardRef, Ref } from 'react';
import IConfig, { IAlertClass } from '../../../models/config';
import IIndex, { IAlertRegion as IIndexAlertRegion } from '../../../models/index';
import { momentFullFormat } from '../../../service/chartDataHandler';
import './VWarnings.scss';

const getWarnColor = (indexAlertRegion: IIndexAlertRegion | undefined, alertClass: IAlertClass) => {
    if (indexAlertRegion) {
        if (indexAlertRegion.preAlert) {
            return `url(#Hatch_${indexAlertRegion.alertClassId})`;
        }
        return alertClass.color;
    }
    return '#93C66A';
};

interface IProps {
    alertRegionId: string;
    config: IConfig;
    index: IIndex;
}

const EWarningsTableRow = ({ alertRegionId, config, index }: IProps, ref: Ref<HTMLTableRowElement>) => {
    const alertRegion = config.alertregions[alertRegionId];
    const indexAlertRegion = index.alertregions[alertRegionId];
    const importedAt = indexAlertRegion?.importedAt;

    const riverAreaName = alertRegion.name;

    const alertClass = config.alertclasses[alertRegion.alertClassId];
    const color = getWarnColor(indexAlertRegion, alertClass);

    return (
        <TableRow component="tr" className="v-warnings__table-row" ref={ref}>
            <TableCell className="v-warnings__table-cell">{riverAreaName}</TableCell>
            <TableCell className="v-warnings__table-cell--color">
                <svg>
                    <rect width="100%" height="100%" fill={color} />
                </svg>
            </TableCell>
            <TableCell className="v-warnings__table-cell">{alertClass.name}</TableCell>
            <TableCell className="v-warnings__table-cell">{momentFullFormat(importedAt)} Uhr</TableCell>
        </TableRow>
    );
};
export default forwardRef(EWarningsTableRow);
