import React, { Component, ErrorInfo, ReactNode } from 'react';

class ErrorBoundary extends Component<
    { errorMsg?: string; children: ReactNode },
    { hasError?: boolean; error?: Error; errorInfo?: ErrorInfo }
> {
    constructor(props: { errorMsg?: string; children: ReactNode }) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
    }

    render() {
        if (this.state.hasError) {
            return <h3>{this.props.errorMsg}</h3>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
