import { Redirect, Route, Switch } from 'react-router-dom';
import routes from '../../config/routes';
import LScrollToTop from '../layout/LScrollToTop';
import V404 from '../views/404-page/V404';
import VDynamicDetail from '../views/dynamic-detail/VDynamicDetail';
import VHome from '../views/home/VHome';
import VLevelList from '../views/level-list/VLevelList';
import VRainfallMaps from '../views/rainfall-maps/VRainfallMaps';
import VStaticPage from '../views/static-page/VStaticPage';
import VStatusReport from '../views/status-report/VStatusReport';
import VWarnings from '../views/warnings/VWarnings';
import VWeatherReport from '../views/weather-report/VWeatherReport';

const LRoutes = () => (
    <LScrollToTop>
        <Switch>
            {/* No home here, it is defined in index.tsx! */}
            <Route exact path={routes.home.path}>
                <VHome />
            </Route>
            <Redirect from="/flussgebiet/kommunale-messstellen/:msSlug" to="/kommunale-messstellen/:msSlug" />
            <Route path={'/flussgebiet/:riverAreaSlug/:msSlug'}>
                <VDynamicDetail />
            </Route>
            <Route path={'/flussgebiet/:riverAreaSlug'}>
                <VDynamicDetail />
            </Route>
            <Route path={routes.levelList.path + '/land'}>
                <VLevelList type={'default'} />
            </Route>
            <Route path={routes.levelList.path + '/kommunal'}>
                <VLevelList type={'municipal'} />
            </Route>
            <Route path={routes.contact.path}>
                <VStaticPage route={routes.contact.path} />
            </Route>
            <Route path={routes.statusReport.path}>
                <VStatusReport />
            </Route>
            <Route path={routes.weatherReport.path}>
                <VWeatherReport />
            </Route>
            <Route path={routes.imprint.path}>
                <VStaticPage route={routes.imprint.path} />
            </Route>
            <Route path={routes.privacyPolicy.path}>
                <VStaticPage route={routes.privacyPolicy.path} />
            </Route>
            <Route path={routes.accessibility.path}>
                <VStaticPage route={routes.accessibility.path} />
            </Route>
            <Route path={routes.help.path}>
                <VStaticPage route={routes.help.path} />
            </Route>
            <Route path={routes.publications.path}>
                <VStaticPage route={routes.publications.path} />
            </Route>
            <Route path={routes.links.path}>
                <VStaticPage route={routes.links.path} />
            </Route>
            <Route path={routes.news.path}>
                <VStaticPage route={routes.news.path} />
            </Route>
            <Route path={routes.levelReportingMarks.path}>
                <VStaticPage route={routes.levelReportingMarks.path} />
            </Route>
            <Route path={routes.sources.path}>
                <VStaticPage route={routes.sources.path} />
            </Route>
            <Route path={routes.helpEarlyAlerts.path}>
                <VStaticPage route={routes.helpEarlyAlerts.path} />
            </Route>
            <Route path={routes.rainfall.path}>
                <VRainfallMaps />
            </Route>
            <Redirect from={routes.warnings.path + '/:alertRegionId'} to={routes.warnings.path} />
            <Route path={routes.warnings.path}>
                <VWarnings />
            </Route>
            <Route path={routes.faq.path}>
                <VStaticPage route={routes.faq.path} />
            </Route>
            {/* IMPORTANT: This is a wildcard route matching anything "xxx/yyy". Therefore it HAS TO BE the last route to check to not conflict with others. */}
            <Route path={'/:riverAreaSlug/:msSlug'}>
                <VDynamicDetail />
            </Route>
            <Route path="">
                <V404 />
            </Route>
        </Switch>
    </LScrollToTop>
);

export default LRoutes;
