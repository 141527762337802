const isValidString = (name: string): boolean => {
    if (name === '' || typeof name !== 'string') {
        console.error(`invalid name "${name}" given to storage service`);
        return false;
    }
    return true;
};

/**
 * Creates a local storage service to deal with JSON values and specify its accepted types
 */
export const localService = {
    get: <T>(name: string): T | undefined => {
        if (isValidString(name)) {
            try {
                const res = localStorage.getItem(name);
                if (res) {
                    return JSON.parse(res) as T;
                }
            } catch (err) {
                console.warn(err);
            }
        }
        return undefined;
    },
    set: <T>(name: string, value: T): void => {
        if (isValidString(name)) {
            try {
                localStorage.setItem(name, JSON.stringify(value));
            } catch (err) {
                console.warn(err);
            }
        }
    },
    add: <T>(name: string, newValue: T): void => {
        const currentValue = localService.get<T>(name);
        const res = Array.isArray(currentValue) ? currentValue : [];
        if (!res.includes(newValue)) {
            res.push(newValue);
        }
        try {
            localService.set(name, res);
        } catch (err) {
            console.warn(err);
        }
    },
    remove: (name: string): void => {
        if (isValidString(name)) {
            try {
                localStorage.removeItem(name);
            } catch (err) {
                console.warn(err);
            }
        }
    },
    clear: (): void => {
        try {
            localStorage.clear();
        } catch (err) {
            console.warn(err);
        }
    },
};
