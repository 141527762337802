import { useState, useEffect } from 'react';
import './MDetailChart.scss';
import EPreviewChart from '../../elements/preview-chart/EPreviewChart';
import { Link } from 'react-router-dom';
import ErrorBoundary from '../../elements/error-boundary/EErrorBoundary';
import { momentFullFormat } from '../../../service/chartDataHandler';
import { IMeasurements } from '../../../models/misc';

interface IProps {
    data: IMeasurements;
    msSlug?: string;
    msName?: string;
    isRiverAreaView?: boolean;
    riverName: string | null;
    riverAreaSlug?: string | null;
    isSeaSite: boolean;
}

const MDetailChart = (props: IProps) => {
    const [lazyPreview, setLazyPreview] = useState(true);

    useEffect(() => {
        let mounted = true;
        setTimeout(() => {
            if (mounted) {
                setLazyPreview(false);
            }
        }, 100);
        return () => {
            // cleanup function
            mounted = false;
        };
    }, []);

    return (
        <div className="m-detail-chart">
            <Link
                to={(props.isRiverAreaView ? '' : 'flussgebiet/') + `${props.riverAreaSlug}/${props.msSlug}`}
                className={'m-detail-chart__link' + (lazyPreview ? ' m-detail-chart__link--lazy-preview' : '')}
            >
                <div className="m-detail-chart__detail">
                    <div className="m-detail-chart__detail__info">
                        <p className="m-detail-chart__detail__info__place">
                            <strong>
                                {props.msName}
                                {props.riverName ? ` / ${props.riverName}` : ''}
                            </strong>
                        </p>
                        <p className="m-detail-chart__detail__info__time small">
                            {props.data.xLast ? momentFullFormat(props.data.xLast) + ' Uhr' : ''}{' '}
                        </p>
                    </div>
                </div>

                <div className="m-detail-chart__chart-wrapper" data-testid="detail-preview-chart">
                    <ErrorBoundary errorMsg={'Die Pegelvorschau konnte nicht geladen werden'}>
                        <EPreviewChart
                            data={props.data}
                            className={'m-detail-chart__detail-line-chart'}
                            isSeaSite={props.isSeaSite}
                            label="Preview Chart"
                        />
                    </ErrorBoundary>
                </div>
            </Link>

            {lazyPreview && <div className={'m-detail-chart__lazy-loading-preview'}>Wird geladen...</div>}
        </div>
    );
};

export default MDetailChart;
