import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { MouseEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useResize } from '../../../utils/hooks';

const EShowBackToTop = () => {
    const [showBackToTop, setShowBackToTop] = useState(false);

    const windowSize = useResize();
    const location = useLocation();

    useEffect(() => {
        const body = document.querySelector('body');
        const bodyHeight = body ? body.scrollHeight : windowSize.height;
        setShowBackToTop(bodyHeight > windowSize.height);
    }, [location, windowSize]);

    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
        const eventTarget = event.target as HTMLDivElement;
        const anchor = (eventTarget?.ownerDocument || document).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };

    return showBackToTop ? (
        <div className="l-footer__top">
            <div className="l-footer__back-to-top" onClick={handleClick}>
                <div className="button">
                    <KeyboardArrowUpIcon style={{ color: 'white' }} />
                </div>
                <span>nach oben</span>
            </div>
        </div>
    ) : null;
};

export default EShowBackToTop;
