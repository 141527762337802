import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect, useState } from 'react';
import queryConfig from '../../../config/query';
import { usePartialRequest } from '../../../service/globalService';
import './MStaticContent.scss';

const IMG_ID = 'wetterwarnungen';

const fetchDwdImage = async (imgSrc: string) => {
    const r = await axios.get<ArrayBuffer>(imgSrc, {
        responseType: 'arraybuffer',
    });
    const image = btoa(new Uint8Array(r.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    const imageBase64 = `data:image/png;base64,${image}`;
    return imageBase64;
};

const MStaticContent = () => {
    const [imgSrc, setImgSrc] = useState<string>(undefined);

    const { data: tiles } = usePartialRequest('/kacheln');

    const { data: imageBase64 } = useQuery({
        queryKey: ['dwdImage', imgSrc],
        queryFn: () => fetchDwdImage(imgSrc),
        enabled: !!imgSrc,
        ...queryConfig.dwdImageRequest,
    });

    useEffect(() => {
        if (tiles) {
            // load the innerHTML of the "kacheln" file and set it the innerHTML state
            const parsed = new DOMParser().parseFromString(tiles, 'text/html');
            const parsedImgSrc = parsed.getElementById(IMG_ID)?.getAttribute('src');

            if (!parsedImgSrc) {
                console.error(`failed to parse IMG Source with id ${IMG_ID}`);
                return;
            }

            setImgSrc(parsedImgSrc);
        }
    }, [tiles]);

    useEffect(() => {
        // set dwd map
        if (imageBase64) {
            const img = document.getElementById(IMG_ID);
            if (img) {
                img.setAttribute('src', imageBase64);
            }
        }
    }, [imageBase64]);

    return (
        <div className="m-static-content">
            <div className="m-static-content__inner" dangerouslySetInnerHTML={{ __html: tiles }} />
        </div>
    );
};

export default MStaticContent;
