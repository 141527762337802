import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useQueries } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { IStatusReport } from '../../../models';
import { configRequestQueryOptions, statusReportGlobalRequestQueryOptions } from '../../../service/globalService';
import { slug } from '../../../utils/helpers';
import EStatusReportLine from './EStatusReportLine';
import './VStatusReport.scss';

const VStatusReport = () => {
    const [{ data: config }, { data: statusReport }] = useQueries({
        queries: [configRequestQueryOptions, statusReportGlobalRequestQueryOptions],
    });

    const location = useLocation();
    const didScrollRef = useRef(false);

    // Check for hash link auto scroll on every render.
    useEffect(() => {
        if (didScrollRef.current) {
            return;
        }

        const hash = location.hash.slice(1);
        if (!hash) {
            return;
        }

        // Smoothly scroll to hash links.
        const targetElement = document.getElementById(hash);
        if (targetElement) {
            // Clear hash part from url.
            // const url = new URL(window.location.href);
            // url.hash = '';
            // window.history.replaceState({}, document.title, url.toString());

            // Offset scroll position by header height and little spacing.
            const offset = document.querySelector('header.l-header').clientHeight; // Höhe des Headers
            targetElement.style.scrollMarginTop = `${offset + 25}px`;

            targetElement.scrollIntoView({
                behavior: 'smooth',
            });

            didScrollRef.current = true;
        }
    });

    return (
        config &&
        statusReport && (
            <div className="v-status-report container">
                <Grid container spacing={4}>
                    <Grid item xs={12} md={9}>
                        <h1 className="v-status-report__headline">Hochwasserbericht</h1>
                        <p className="v-status-report__publisher">
                            Herausgegeben von der Hochwasservorhersagezentrale am Landesamt für Umwelt in Mainz
                        </p>

                        <EStatusReportLine
                            statusReport={statusReport as IStatusReport}
                            level={'root'}
                            id={'rheinland-pfalz'}
                            title={'Überblick zur aktuellen Situation'}
                        />

                        <div>
                            {statusReport.riverAreas &&
                                Object.entries(statusReport.riverAreas).map(([riverAreaId, riverAreaStatusReport]) => {
                                    const riverArea = config.riverareas[riverAreaId];
                                    return (
                                        <EStatusReportLine
                                            statusReport={riverAreaStatusReport}
                                            level={'riverarea'}
                                            id={riverArea.slug}
                                            title={riverArea.name}
                                            key={`river-area-status-report-${riverAreaId}`}
                                        >
                                            {riverAreaStatusReport.rivers &&
                                                Object.entries(riverAreaStatusReport.rivers).map(
                                                    ([riverId, riverStatusReport]) =>
                                                        riverArea.rivers[riverId] && (
                                                            <EStatusReportLine
                                                                statusReport={riverStatusReport}
                                                                level={'river'}
                                                                id={`${riverArea.slug}-${slug(riverArea.rivers[riverId].name)}`}
                                                                title={riverArea.rivers[riverId].name}
                                                                key={`river-status-report-${riverId}`}
                                                            />
                                                        )
                                                )}
                                        </EStatusReportLine>
                                    );
                                })}
                        </div>

                        <p className="v-status-report__notice">Angaben ohne Gewähr</p>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <div className="l-content-box">
                            <Button variant="contained" href={'/'} className="button">
                                Zurück zur Kartenansicht
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    );
};

export default VStatusReport;
