import './MMenuOverlay.scss';

interface IProps {
    isOpen: boolean;
}

const MMenuOverlay = ({ isOpen }: IProps) => {
    return <div className={'menu-overlay' + (isOpen ? ' menu-overlay--open' : '')} />;
};

export default MMenuOverlay;
