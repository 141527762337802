import { Link } from 'react-router-dom';
import routes from '../../../config/routes';
import './EHelpIcon.scss';

export interface IHelpIconProps {
    hash?: string;
    label: string;
}

const EHelpIcon = (props: IHelpIconProps) => {
    return (
        <Link
            className="e-help-icon"
            to={routes.help.path + (props.hash ? '#' + props.hash : '')}
            aria-label={props.label}
        />
    );
};

export default EHelpIcon;
