import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useStatusReportGlobalRequest } from '../../../service/globalService';
import './VWeatherReport.scss';

const VWeatherReport = () => {
    const { data: statusReport } = useStatusReportGlobalRequest();

    return (
        statusReport && (
            <div className="v-status-report container">
                <Grid container spacing={4}>
                    <Grid item xs={12} md={9}>
                        <h1 className="v-status-report__headline">Wetterbericht</h1>

                        <div className="v-status-report__root">
                            {'weather' in statusReport && statusReport.weather && (
                                <p dangerouslySetInnerHTML={{ __html: statusReport.weather }}></p>
                            )}
                        </div>

                        <p className="v-status-report__notice">Angaben ohne Gewähr</p>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <div className="l-content-box">
                            <Button variant="contained" href={'/'} className="button">
                                Zurück zur Kartenansicht
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    );
};

export default VWeatherReport;
