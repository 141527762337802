import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import routes from '../../../config/routes';
import { useConfigRequest } from '../../../service/globalService';
import './LBreadcrumbBar.scss';

const LBreadcrumbBar = () => {
    const [navItems, setNavItems] = useState([]);
    const location = useLocation();

    const { data: config } = useConfigRequest();

    useEffect(() => {
        if (config) {
            // Determine breadcrumb links for current page.
            const navItemsTmp = [];
            const segments = location.pathname.split('/');
            // Remove root element.
            segments.shift();

            const root = segments.shift();
            if (root) {
                // Handle river areas.
                if (root === 'flussgebiet') {
                    const raSlug = segments.shift();
                    const riverArea = config.slugs[raSlug];
                    if (riverArea) {
                        if (riverArea.id === 8888888888) {
                            navItemsTmp.push({
                                label: riverArea.name,
                                path: '/pegelliste/kommunal',
                            });
                        } else {
                            navItemsTmp.push({
                                label: riverArea.name,
                                path: '/flussgebiet/' + raSlug,
                            });
                        }
                        // Handle measurement sites.
                        if (segments.length) {
                            const msSlug = segments.shift();
                            const measurementSite = riverArea.measurementsites[msSlug];
                            if (measurementSite) {
                                navItemsTmp.push({
                                    label: measurementSite.name,
                                    path: '/flussgebiet/' + raSlug + '/' + msSlug,
                                });
                            }
                        }
                    }
                    // Handle measurement site lists.
                } else if (root === 'pegelliste') {
                    const msListSlug = segments.shift();
                    if (msListSlug === 'kommunal') {
                        navItemsTmp.push({
                            label: 'Kommunale Messstellen',
                            path: '/pegelliste/kommunal',
                        });
                    } else {
                        navItemsTmp.push({
                            label: 'HVZ-Pegel',
                            path: '/pegelliste/land',
                        });
                    }
                } else if (root === 'kommunale-messstellen') {
                    // Handle municipal site lists.
                    const raSlug = segments.shift();
                    const riverArea = config.slugs[root];
                    navItemsTmp.push({
                        label: riverArea.name,
                        path: '/pegelliste/kommunal',
                    });
                    const measurementSite = riverArea.measurementsites[raSlug];
                    if (measurementSite) {
                        navItemsTmp.push({
                            label: measurementSite.name,
                            path: '/' + root + '/' + raSlug + '/',
                        });
                    }
                } else {
                    // Handle other pages.
                    const route = Object.keys(routes).find((r) => routes[r].path === location.pathname);
                    if (route) {
                        navItemsTmp.push(routes[route]);
                    }
                }
            }
            setNavItems(navItemsTmp);
        }
    }, [location, config]);

    return (
        <div className="l-breadcrumb-bar">
            <div className="container">
                <div className="l-breadcrumb-bar__inner">
                    <Link className="path-home" tabIndex={1} to={routes.home.path}>
                        {routes.home.label}
                    </Link>
                    {navItems.map((item: { label: string; path: string }, i: number) => (
                        <span className="path-wrapper" key={i}>
                            <ArrowRightIcon className="path-divider" />
                            {i === navItems.length - 1 ? (
                                <span className="path-current">{item.label}</span>
                            ) : (
                                <Link className="path-link" tabIndex={1} to={item.path}>
                                    {item.label}
                                </Link>
                            )}
                        </span>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LBreadcrumbBar;
