import { CircleMarkerProps, createPathComponent, LeafletContextInterface, updateCircle } from '@react-leaflet/core';
import { Canvas, CircleMarker, Point } from 'leaflet';

function getPredictionColor(hexColor?: string): 'black' | 'white' {
    if (!hexColor) {
        return 'black';
    }

    const cleanedHex = hexColor.startsWith('#') ? hexColor.slice(1) : hexColor;

    const R = parseInt(cleanedHex.slice(0, 2), 16);
    const G = parseInt(cleanedHex.slice(2, 4), 16);
    const B = parseInt(cleanedHex.slice(4, 6), 16);

    const gamma = 2.2;

    // Disable prettier formatting here because this calculation is far better readable in 3 lines than in 1.
    // eslint-disable-next-line prettier/prettier
    const luminance =
        0.2126 * Math.pow(R / 255, gamma) + // eslint-disable-line prettier/prettier
        0.7152 * Math.pow(G / 255, gamma) + // eslint-disable-line prettier/prettier
        0.0722 * Math.pow(B / 255, gamma); // eslint-disable-line prettier/prettier

    return luminance > Math.pow(0.5, gamma) ? 'black' : 'white';
}

Canvas.include({
    /**
     * Draw prediction marker "V" on map canvas.
     *
     * @param {PredictionMarkerElement} layer
     */
    _updateCustomPredictionMarker: function (layer: PredictionMarkerElement) {
        // eslint-disable-next-line @typescript-eslint/dot-notation, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        if (!this['_drawing'] || layer['_empty']()) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/dot-notation, @typescript-eslint/no-unsafe-assignment
        const p: Point = layer['_point'];
        // eslint-disable-next-line @typescript-eslint/dot-notation, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        const ctx: CanvasRenderingContext2D = this['_ctx'];
        // eslint-disable-next-line @typescript-eslint/dot-notation, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
        const radius = Math.max(Math.round(layer['_radius']), 1);
        const r = Math.round(radius - (radius / 100) * 70);

        ctx.strokeStyle = getPredictionColor(layer.options.fillColor);

        ctx.lineWidth = 1.5;

        ctx.beginPath();

        ctx.moveTo(p.x - r, p.y - r);
        ctx.lineTo(p.x, p.y + r);
        ctx.lineTo(p.x + r, p.y - r);

        ctx.stroke();
    },
});

export class PredictionMarkerElement extends CircleMarker {
    _updatePath() {
        // Render original circle.
        // eslint-disable-next-line @typescript-eslint/dot-notation, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        super['_updatePath']();
        // Render "V" on top.
        // eslint-disable-next-line @typescript-eslint/dot-notation, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        this['_renderer']['_updateCustomPredictionMarker'](this);
    }
}

const createLeafletElement = (
    { center, children: _c, ...options }: CircleMarkerProps,
    ctx: LeafletContextInterface
) => {
    const instance = new PredictionMarkerElement(center, options);
    return { instance, context: { ...ctx, overlayContainer: instance } };
};

const PredictionMarker = createPathComponent(createLeafletElement, updateCircle);

export default PredictionMarker;
