import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useQueries } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IHint } from '../../../models';
import { IMeasurementSiteConfig, IRiver, IRiverArea } from '../../../models/config';
import {
    riverAreaDetailRequestQueryOptions,
    useConfigRequest,
    useMeasurementSiteDetailRequestOptions,
} from '../../../service/globalService';
import ErrorBoundary from '../../elements/error-boundary/EErrorBoundary';
import EHelpIcon from '../../elements/help-icon/EHelpIcon';
import EPeaklevels from '../../elements/peaklevels/EPeaklevels';
import EStatusReport from '../../elements/status-report/EStatusReport';
import EStatusReportList from '../../elements/status-report/EStatusReportList';
import VWatertrend from '../../elements/watertrend/VWatertrend';
import MDetailMeasurementSites from '../../modules/detail-measurementsites/MDetailMeasurementSites';
import MDetailMenu from '../../modules/detail-menu/MDetailMenu';
import MHintMessage from '../../modules/hint-message/MHintMessage';
import MRiverAreaDetailBox from '../../modules/riverarea-detailbox/MRiverAreaDetailBox';
import V404 from '../404-page/V404';
import './VDynamicDetail.scss';

const VDynamicDetail = () => {
    const { data: config, isLoading } = useConfigRequest();

    const [riverAreaConfig, setRiverAreaConfig] = useState<IRiverArea>(null);
    const [riverConfig, setRiverConfig] = useState<IRiver>(null);
    const [msConfig, setMsConfig] = useState<IMeasurementSiteConfig>(null);

    const [is404Page, setIs404Page] = useState<boolean>(false);

    const { riverAreaSlug, msSlug } = useParams<{ riverAreaSlug: string; msSlug?: string }>();

    const [municipalHint, setMunicipalHint] = useState<IHint>(null);

    const raSlugData = config?.slugs[riverAreaSlug] ?? null;
    const msSlugData = (msSlug && raSlugData?.measurementsites[msSlug]) || null;

    const [{ data: riverAreaDetails }, { data: msData }] = useQueries({
        queries: [
            riverAreaDetailRequestQueryOptions(msSlug ? undefined : raSlugData?.id),
            useMeasurementSiteDetailRequestOptions(msSlugData?.ms_number),
        ],
    });

    // Init details.
    useEffect(() => {
        if (config) {
            /**
             * check if url slugs are present in config slugs
             */
            let slugFound: boolean = false;
            if (raSlugData) {
                setRiverAreaConfig(config.riverareas[raSlugData.id]);

                const msSlugDataTmp = msSlug && raSlugData.measurementsites[msSlug];
                if (msSlugDataTmp) {
                    const msConfigTmp = config.measurementsite[msSlugDataTmp.ms_number];
                    setMsConfig(msConfigTmp);
                    setRiverConfig(config.riverareas[raSlugData.id].rivers[msConfigTmp.rivers[0]]);
                    slugFound = true;

                    if (msConfigTmp.type === 'municipal') {
                        setMunicipalHint({
                            type: 'municipal',
                            text: config.settings.measurementSiteMunicipalHint,
                            updatedAt: new Date().toISOString(),
                        });
                    }
                } else if (!msSlug) {
                    setRiverConfig(null);
                    setMsConfig(null);
                    slugFound = true;
                }
            }

            if (!slugFound) {
                console.warn('url slug not found');
                setIs404Page(true);
            }
        }
    }, [config, msSlug, raSlugData]);

    if (isLoading) {
        return (
            <div className="loading-screen">
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className="v-detail-measurement">
            {is404Page ? (
                <V404 />
            ) : (
                <div className="container">
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={9}>
                            <div className="content-box">
                                {municipalHint && <MHintMessage hint={municipalHint} />}
                                {msConfig && (
                                    <h1>
                                        Pegel {msConfig.name}
                                        {riverConfig?.name && ` / ${riverConfig.name}`}
                                        {msConfig.riverMunicipalName && ` / ${msConfig.riverMunicipalName}`}
                                        {msConfig.type === 'municipal' && ' (Kommunale Messstelle)'}
                                    </h1>
                                )}
                                {riverAreaDetails && riverAreaConfig && !msSlug && (
                                    <h1>Flussgebiet {riverAreaConfig.name}</h1>
                                )}

                                {/* Status reports and hints. */}
                                {msData?.statusReport && msSlug && (
                                    <ErrorBoundary errorMsg={'Hochwasserbericht nicht verfügbar'}>
                                        <EStatusReport statusReport={msData.statusReport} isDetailView />
                                    </ErrorBoundary>
                                )}
                                {msData?.hint && (
                                    <ErrorBoundary errorMsg={'Hochwasserbericht nicht verfügbar'}>
                                        <MHintMessage hint={msData.hint} showHelpIcon />
                                    </ErrorBoundary>
                                )}
                                {riverAreaDetails?.statusReports && (
                                    <ErrorBoundary errorMsg={'Hochwasserbericht nicht verfügbar'}>
                                        <EStatusReportList
                                            riverAreaId={raSlugData?.id}
                                            statusReports={riverAreaDetails.statusReports}
                                            isDetailView
                                        />
                                    </ErrorBoundary>
                                )}
                                {riverAreaDetails?.hint && (
                                    <ErrorBoundary errorMsg={'Hochwasserbericht nicht verfügbar'}>
                                        <MHintMessage hint={riverAreaDetails.hint} showHelpIcon />
                                    </ErrorBoundary>
                                )}

                                {config && msData && msSlug && msConfig ? (
                                    /* Measurement site details. */
                                    <ErrorBoundary errorMsg={'Details konnten nicht geladen werden'}>
                                        <MDetailMeasurementSites
                                            msConfig={config.measurementsite[msConfig.number]}
                                            msNumber={msConfig.number}
                                            measurementSitesData={msData}
                                            legends={config.legends}
                                            operator={config.operators[msConfig.number]}
                                        />
                                    </ErrorBoundary>
                                ) : (
                                    /* River area details. */
                                    <>
                                        {riverAreaDetails?.waterLevelTrends &&
                                            riverAreaDetails?.statusReports?.length && (
                                                <>
                                                    <h2>
                                                        Aktuelle Pegelstände
                                                        <EHelpIcon
                                                            hash={''}
                                                            label="Hinweise zur Übersicht der Wasserstände"
                                                        />
                                                    </h2>
                                                    <ErrorBoundary>
                                                        <VWatertrend
                                                            config={config}
                                                            waterLevelTrends={riverAreaDetails.waterLevelTrends}
                                                            riverAreaSlug={riverAreaSlug}
                                                        />
                                                    </ErrorBoundary>
                                                </>
                                            )}
                                        {riverAreaDetails?.peaklevel && (
                                            <>
                                                <h2>
                                                    Höchststände
                                                    <EHelpIcon
                                                        hash={'hoechststaende'}
                                                        label="Hinweise zur Übersicht der Höchststände"
                                                    />
                                                </h2>
                                                <ErrorBoundary>
                                                    <EPeaklevels
                                                        data={riverAreaDetails}
                                                        slug={config.slugs[riverAreaSlug]}
                                                        currentRiverArea={riverAreaSlug}
                                                        riverArea={riverAreaConfig}
                                                        measurementSites={config.measurementsite}
                                                    />
                                                </ErrorBoundary>
                                            </>
                                        )}
                                        {riverAreaDetails?.rivers && riverAreaConfig && (
                                            <ErrorBoundary>
                                                <MRiverAreaDetailBox
                                                    riverAreaMSSlugs={config.slugs[riverAreaSlug].measurementsites}
                                                    riverAreaRivers={riverAreaConfig.rivers}
                                                    rivers={riverAreaDetails.rivers}
                                                    riverAreaSlugKey={riverAreaSlug}
                                                    measurementSites={config.measurementsite}
                                                />
                                            </ErrorBoundary>
                                        )}
                                    </>
                                )}
                            </div>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            {config && (
                                <ErrorBoundary errorMsg={'Menü konnte nicht geladen werden'}>
                                    <MDetailMenu riverAreaSlug={riverAreaSlug} msSlug={msSlug} />
                                </ErrorBoundary>
                            )}
                        </Grid>
                    </Grid>
                </div>
            )}
        </div>
    );
};

export default VDynamicDetail;
