import { Link } from 'react-router-dom';
import IconAlert from '../../../assets/images/icon-alert.svg';
import IconInfo from '../../../assets/images/icon-info.svg';
import routes from '../../../config/routes';
import { IStatusReport } from '../../../models';
import IConfig from '../../../models/config';
import { momentFullFormat } from '../../../service/chartDataHandler';
import { useConfigRequest } from '../../../service/globalService';
import EAccordeon from '../accordeon/EAccordeon';
import './EStatusReport.scss';

export interface IStatusReportProps {
    isDetailView: boolean;
    statusReport: IStatusReport | null;
}

function getAreaName(config: IConfig | null, statusReport: IStatusReport | null, isDetailView: boolean): string {
    if (!config || !statusReport) {
        return '';
    }
    const riverAreaId = statusReport.riverAreaId;

    if (riverAreaId && isDetailView) {
        const riverArea = config.riverareas[riverAreaId];

        if (statusReport.riverParameter) {
            return riverArea.rivers[statusReport.riverParameter].name;
        }
        return riverArea.name;
    }

    return 'Rheinland Pfalz';
}

const EStatusReport = ({ isDetailView, statusReport }: IStatusReportProps) => {
    const { data: config } = useConfigRequest();
    const areaName = getAreaName(config, statusReport, isDetailView);
    return (
        statusReport && (
            <div className={'e-status-report' + (isDetailView ? ' detail' : '')}>
                <div className={'e-status-report__icon' + (!statusReport.isInfo ? ' is-warning' : '')}>
                    <img alt={'Hochwasserbericht'} src={statusReport.isInfo ? IconInfo : IconAlert} />
                </div>

                <div className="e-status-report__body">
                    <EAccordeon externalIsOpen={false}>
                        {{
                            heading: (
                                <>
                                    <p className="h5">{areaName}</p>
                                    {statusReport.date && (
                                        <p className="e-status-report__subheadline">
                                            {statusReport.isInfo ? 'Infobericht' : 'Hochwasserbericht'} vom{' '}
                                            {momentFullFormat(statusReport.date)} Uhr
                                        </p>
                                    )}
                                </>
                            ),

                            content: (
                                <>
                                    {statusReport.info && (
                                        <div
                                            className="e-status-report__content"
                                            dangerouslySetInnerHTML={{
                                                __html: statusReport.info,
                                            }}
                                        ></div>
                                    )}

                                    <Link
                                        className="e-status-report__link"
                                        to={
                                            routes.statusReport.path +
                                            (statusReport.riverAreaId
                                                ? '#' + config.riverareas[statusReport.riverAreaId]?.slug
                                                : '')
                                        }
                                    >
                                        Zum Bericht
                                    </Link>
                                </>
                            ),
                        }}
                    </EAccordeon>
                </div>
            </div>
        )
    );
};

export default EStatusReport;
