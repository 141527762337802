import { Map, PathOptions } from 'leaflet';
import { memo, ReactNode, useMemo, useRef } from 'react';
import { CircleMarker, Tooltip, useMap, useMapEvents } from 'react-leaflet';
import { useHistory } from 'react-router-dom';
import IIndex from '../../../models';
import IConfig from '../../../models/config';
import { checkTouchDevice, unproject, zoomToPointRadius } from '../../../utils/mapUtils';
import MunicipalMarker from '../marker/MunicipalMarker';
import PredictionMarker from '../marker/PredictionMarker';
import {
    EMap__IMeasurementSitePreview as IMeasurementSitePreview,
    EMap__MeasurementSitePreviewContent as MeasurementSitePreviewContent,
} from './EMap__MeasurementSitePreview';

const unprojectMeasurementSite = (
    easting: number,
    northing: number,
    eastingOffset?: number,
    northingOffset?: number
) => {
    // Modify point coordinates for easting or northing offset.
    if (eastingOffset || northingOffset) {
        if (eastingOffset) {
            easting += eastingOffset;
        }
        if (northingOffset) {
            northing += northingOffset;
        }
    }
    return unproject([easting, northing]);
};

const isTouchDevice = checkTouchDevice();

/**
 * Point Component Leaflet Layer
 * used to display and handle stations
 */
interface IProps {
    config: IConfig;
    index: IIndex;
    // setMobilePreview: SetStateAction<IMeasurementSitePreview>;
    setMobilePreview: React.Dispatch<React.SetStateAction<IMeasurementSitePreview>>;
    showMunicipal: boolean;
}

// Project approved.
// eslint-disable-next-line @typescript-eslint/naming-convention
const EMap__MeasurementSiteMarkers = ({ config, index, setMobilePreview, showMunicipal }: IProps) => {
    // Ensure required properties are set.
    if (!config || !index) {
        throw new Error('Missing props: config and index are mandatory.');
    }

    /**
     * IMPORTANT! NO STATES HERE!!!
     * It badly influences map rendering performance. Scaling of points is ONLY
     * done using map events and native leaflet API.
     */

    const map = useMap();
    const radius = zoomToPointRadius(map.getZoom());

    const history = useHistory();
    const leafletMarkers = useRef<Record<string, L.CircleMarker>>({});

    // Remove duplicate numbers and sort markers such that muncipal markers are always behind normal ones.
    // Hint: The resulting list is reversed compared to measurementsitesorted because we are drawing markers from bottom to top.
    const msNumbers = useMemo<number[]>(
        () =>
            Array.from(new Set(Object.values(config.measurementsitesorted).flat()))
                .reverse()
                .sort((a, b) => {
                    const msDataA = config.measurementsite[a];
                    const msDataB = config.measurementsite[b];
                    if (msDataA.type === 'municipal' && msDataB.type !== 'municipal') {
                        return -1;
                    }
                    if (msDataB.type === 'municipal' && msDataA.type !== 'municipal') {
                        return 1;
                    }
                    return 0;
                }),
        [config]
    );

    // Create markers once.
    const markers = useMemo<ReactNode[]>(() => {
        // Clear all leaflet marker instances referenced during last run.
        leafletMarkers.current = {};
        const result: ReactNode[] = [];

        msNumbers.forEach((msNumber: number) => {
            const msConfig = config.measurementsite[msNumber];
            const msData = index.measurementSites[msNumber];
            const kind = msConfig.type;

            if (kind === 'municipal' && !showMunicipal) {
                return;
            }

            const riverAreaId: number | undefined = msConfig.riverAreas[0];
            const riverParameter: string | undefined = msConfig.rivers[0];
            const previewData: IMeasurementSitePreview = {
                msConfig,
                msData,
                riverAreaSlug: config.riverareas[riverAreaId]?.slug,
                riverName: config.rivers[riverParameter]?.name,
                riverMunicipalName: msConfig.riverMunicipalName,
            };
            const latLng = unprojectMeasurementSite(
                msConfig.easting,
                msConfig.northing,
                msConfig.eastingOffset,
                msConfig.northingOffset
            );
            const Marker =
                kind === 'default' ? (msConfig.hasPrediction ? PredictionMarker : CircleMarker) : MunicipalMarker;

            const pathOptions: PathOptions = {
                color: 'black',
                fill: true,
                fillColor: msData?.legendColor || '#ffffff',
                fillOpacity: 1,
                opacity: 1,
                weight: 1,
            };
            result.push(
                <Marker
                    center={latLng}
                    radius={radius}
                    // React-leaflet issue. Inherited props from pathOptions of <Path> are only recognized on first render. We have to define all path options twice - as prop and as `pathOptions` prop itself!
                    {...pathOptions}
                    pathOptions={pathOptions}
                    key={msNumber}
                    ref={(ref: L.CircleMarker) => {
                        if (ref) {
                            leafletMarkers.current[msNumber] = ref;
                        } else {
                            delete leafletMarkers.current[msNumber];
                        }
                    }}
                    eventHandlers={
                        msData && {
                            click: (e) => {
                                // @ts-expect-error Wrong type here. The property exists.
                                e.originalEvent._stopped = true;
                                if (isTouchDevice) {
                                    setMobilePreview(previewData);
                                } else {
                                    if (previewData.riverAreaSlug && msConfig.slug && msConfig.type === 'municipal') {
                                        history.push('/' + previewData.riverAreaSlug + '/' + msConfig.slug);
                                    } else if (previewData.riverAreaSlug && msConfig.slug) {
                                        history.push('/flussgebiet/' + previewData.riverAreaSlug + '/' + msConfig.slug);
                                    }
                                }
                                return false;
                            },
                        }
                    }
                >
                    {!isTouchDevice && (
                        <Tooltip opacity={1} className="e-map__tooltip">
                            <MeasurementSitePreviewContent {...previewData} />
                        </Tooltip>
                    )}
                </Marker>
            );
        });

        return result;
    }, [config, index, setMobilePreview, history, showMunicipal, radius, msNumbers]);

    useMapEvents({
        zoomend: (e) => {
            const target = e.target as Map;
            // Directly update marker radius on leaflet objects instead of using a state to improve map performance.
            const zoomedRadius = zoomToPointRadius(target.getZoom());
            for (const msNumber in leafletMarkers.current) {
                if (Object.prototype.hasOwnProperty.call(leafletMarkers.current, msNumber)) {
                    leafletMarkers.current[msNumber].setRadius(zoomedRadius);
                }
            }
        },
    });

    return <>{markers}</>;
};

export default EMap__MeasurementSiteMarkers;

// Project approved.
// eslint-disable-next-line @typescript-eslint/naming-convention
export const EMap__MemoizedMeasurementSiteMarkers = memo(EMap__MeasurementSiteMarkers);
