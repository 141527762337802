import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { IMeasurementSiteConfig, IRiverArea, ISlug } from '../../../models/config';
import { IRiverAreaDetails } from '../../../models/misc';
import { momentFormatDate, momentFormatHour, momentFullFormat } from '../../../service/chartDataHandler';
import './EPeaklevels.scss';

interface IProps {
    data: IRiverAreaDetails | null;
    slug: ISlug | null;
    currentRiverArea: string | null;
    riverArea: IRiverArea;
    measurementSites: Record<string, IMeasurementSiteConfig>;
}

const EPeaklevels = ({ currentRiverArea, data, measurementSites, riverArea, slug }: IProps) => {
    const getSlug = (msNumber: string): string => {
        return (
            slug &&
            Object.keys(slug?.measurementsites).find((msSlug) => {
                return slug.measurementsites[msSlug].ms_number.toString() === msNumber;
            })
        );
    };

    return (
        <div className="e-peaklevels">
            {data && (
                <>
                    <div className="e-peaklevels__table" key={-1}>
                        <div className="e-peaklevels__table-row top">
                            <div className="e-peaklevels__table-cell heading-top">Fluss</div>
                            <div className="e-peaklevels__table-cell heading-top">Wasserstände</div>
                            <div className="e-peaklevels__table-cell heading-top">Zeitraum</div>
                        </div>
                    </div>

                    {Object.keys(data?.rivers).map((riverKey, key) => {
                        const river = data.rivers[riverKey];
                        const riverName = riverArea?.rivers[riverKey]?.name;
                        const peakLevels = data.peaklevel;
                        // Check if river has any measurementSite with peaklevel-data
                        const hasMsWithPeaklevel =
                            peakLevels &&
                            river?.measurementSites &&
                            Object.keys(river.measurementSites).some((msNumber) => peakLevels[msNumber]);

                        return (
                            hasMsWithPeaklevel && (
                                <TableContainer key={key} className="e-peaklevels__table">
                                    <Table>
                                        <TableHead className="e-peaklevels__table-head">
                                            <TableRow className="e-peaklevels__table-row">
                                                <TableCell className="e-peaklevels__table-cell heading">
                                                    {riverName}
                                                </TableCell>
                                                <TableCell className="e-peaklevels__table-cell heading"></TableCell>
                                                <TableCell className="e-peaklevels__table-cell heading"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.keys(river.measurementSites).map((msNumber, index) => {
                                                const msConfig = measurementSites[msNumber];
                                                const peakLevelData = data.peaklevel[msNumber];
                                                const msSlug = getSlug(msNumber) || '';
                                                const unit = msConfig?.isSeaSite ? ' m ü. NHN' : ' cm';
                                                if (peakLevelData) {
                                                    if (
                                                        peakLevelData.isMeasurement ||
                                                        peakLevelData.isMeasurement === null
                                                    ) {
                                                        return (
                                                            <TableRow key={index} className="e-peaklevels__table-row">
                                                                <TableCell className="e-peaklevels__table-cell">
                                                                    <Link
                                                                        to={
                                                                            '/flussgebiet/' +
                                                                            currentRiverArea +
                                                                            '/' +
                                                                            msSlug
                                                                        }
                                                                    >
                                                                        {msConfig?.name}
                                                                    </Link>
                                                                </TableCell>
                                                                <TableCell className="e-peaklevels__table-cell">
                                                                    <span>
                                                                        {peakLevelData.value ? 'erreicht ' : ''}
                                                                        {peakLevelData.value ? (
                                                                            <>
                                                                                erreicht{' '}
                                                                                <strong>
                                                                                    {peakLevelData.value.toLocaleString() +
                                                                                        unit}
                                                                                </strong>
                                                                            </>
                                                                        ) : (
                                                                            '-'
                                                                        )}
                                                                    </span>
                                                                </TableCell>
                                                                <TableCell className="e-peaklevels__table-cell">
                                                                    {peakLevelData.value
                                                                        ? momentFullFormat(peakLevelData.dateTime) +
                                                                          ' Uhr'
                                                                        : '-'}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    }
                                                    const fromDate =
                                                        peakLevelData.dateTimeFrom &&
                                                        momentFormatDate(peakLevelData.dateTimeFrom);
                                                    const fromTime =
                                                        peakLevelData.dateTimeFrom &&
                                                        momentFormatHour(peakLevelData.dateTimeFrom);
                                                    const toDate =
                                                        peakLevelData.dateTimeTo &&
                                                        momentFormatDate(peakLevelData.dateTimeTo);
                                                    const toTime =
                                                        peakLevelData.dateTimeTo &&
                                                        momentFormatHour(peakLevelData.dateTimeTo);

                                                    return (
                                                        <TableRow key={index} className="e-peaklevels__table-row">
                                                            <TableCell className="e-peaklevels__table-cell">
                                                                <Link
                                                                    to={
                                                                        '/flussgebiet/' +
                                                                        currentRiverArea +
                                                                        '/' +
                                                                        msSlug
                                                                    }
                                                                >
                                                                    {msConfig?.name}
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell className="e-peaklevels__table-cell">
                                                                <span>
                                                                    erwartet{' '}
                                                                    {
                                                                        // Prettier adding 2 spaces when using ternary operator.
                                                                        // @see https://github.com/prettier/prettier/issues/4979
                                                                        // prettier-ignore
                                                                        peakLevelData.valueMin === peakLevelData.valueMax
                                                                            ? peakLevelData.valueMin.toLocaleString() + unit
                                                                            : (
                                                                                peakLevelData.valueMin.toLocaleString() + unit +
                                                                                ' - ' +
                                                                                peakLevelData.valueMax.toLocaleString() + unit
                                                                            )
                                                                    }
                                                                </span>
                                                            </TableCell>
                                                            <TableCell className="e-peaklevels__table-cell">
                                                                {fromDate === toDate
                                                                    ? `${fromDate} ${fromTime} - ${toTime} Uhr`
                                                                    : `${fromDate} ${fromTime} - ${toDate} ${toTime} Uhr`}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )
                        );
                    })}
                </>
            )}
        </div>
    );
};

export default EPeaklevels;
