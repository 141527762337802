import '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import '@material-ui/lab';
import '@mui/material';
import { DataGrid, deDE, GridColumns, GridSortModel } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IRiverArea } from '../../../models/config';
import { IMeasurements } from '../../../models/misc';
import { momentFullFormat } from '../../../service/chartDataHandler';
import { useConfigRequest, useIndexRequest } from '../../../service/globalService';
import './VLevelList.scss';

interface IProps {
    type: string;
}

interface IRowModel {
    id: string | null;
    name: string | null;
    slug: string | null;
    riverAreaName: string;
    riverAreaSlug: string;
    riverName: string | undefined;
    riverMunicipalName: string | null;
    xLast: string | undefined;
    yLast: number | undefined;
    unit: string;
    legendColor: string | undefined;
    catchmentArea: number | null;
    mh1: number | null;
}

const VLevelList = (props: IProps) => {
    const [showLevel, setShowLevel] = useState(props.type);
    const [rows, setRows] = useState<IRowModel[]>([]);
    const defaultSortModel: GridSortModel = [{ field: 'name', sort: 'asc' }];

    const { data: config } = useConfigRequest();

    const { data: index } = useIndexRequest();

    useEffect(() => setShowLevel(props.type), [props.type]);

    useEffect(() => {
        const newRows =
            config && index
                ? Object.entries(config.measurementsite)
                      .filter(([, msConfig]) => showLevel === 'all' || msConfig.type === showLevel)
                      .map(([msNumber, msConfig]) => {
                          const msData: IMeasurements | null = index.measurementSites
                              ? index.measurementSites[msNumber]
                              : null;
                          const riverAreaConfig: IRiverArea = config.riverareas[msConfig.riverAreas[0]];
                          const unit: string = msConfig.isSeaSite ? 'm ü. NHN' : 'cm';
                          return {
                              // Id field is mandatory for mui data grids.
                              id: msNumber,
                              name: msConfig.name,
                              slug: msConfig.slug,
                              riverAreaName: riverAreaConfig ? riverAreaConfig.name : 'Sonstige',
                              riverAreaSlug: riverAreaConfig ? riverAreaConfig.slug : 'sonstige',
                              riverName: config.rivers[msConfig.rivers[0]]?.name,
                              riverMunicipalName: msConfig.riverMunicipalName,
                              xLast: msData?.xLast,
                              yLast: msData?.yLast,
                              unit,
                              legendColor: msData?.legendColor,
                              catchmentArea: msConfig.catchmentArea,
                              mh1: msConfig.mh1,
                          };
                      })
                : [];
        setRows(newRows);
    }, [showLevel, config, index]);

    function handleSortModelChange(newSortModel: GridSortModel) {
        if (newSortModel.length === 0) {
            newSortModel.push(defaultSortModel[0]);
        }
    }

    const columns: GridColumns<IRowModel> = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            minWidth: showLevel === 'municipal' ? 180 : 160,
            sortComparator: (v1: string, v2: string) => v1.localeCompare(v2),
            renderCell: (params) => {
                const row = params.row;
                if (showLevel === 'municipal') {
                    return row.riverAreaSlug && row.slug ? (
                        <Link to={'/' + row.riverAreaSlug + '/' + row.slug}>{params.row.name}</Link>
                    ) : (
                        params.row.name
                    );
                }
                return row.riverAreaSlug && row.slug ? (
                    <Link to={'/flussgebiet/' + row.riverAreaSlug + '/' + row.slug}>{params.row.name}</Link>
                ) : (
                    params.row.name
                );
            },
        },
        {
            field: 'riverName',
            headerName: 'Gewässer',
            flex: 1,
            minWidth: showLevel === 'municipal' ? 230 : 150,
            renderCell: (params) => {
                const row = params.row;
                if (showLevel === 'municipal') {
                    return row.riverMunicipalName || '';
                }
                return params.row.riverName;
            },
        },
        {
            field: 'riverAreaName',
            headerName: 'Flussgebiet',
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'yLast',
            type: 'number',
            headerName: 'Letzte',
            flex: 1,
            minWidth: 100,
            headerAlign: 'right',
            align: 'right',
            renderCell: (params) => params.row.yLast && params.row.yLast + ' ' + params.row.unit,
        },
        {
            field: 'xLast',
            type: 'dateTime',
            headerName: 'Messung',
            flex: 1,
            minWidth: 160,
            renderCell: (params) => params.row.xLast && momentFullFormat(params.row.xLast) + ' Uhr',
        },
        {
            field: 'legendColor',
            headerName: 'Status',
            flex: 1,
            minWidth: 70,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <div
                        style={{ background: params.row.legendColor }}
                        className={`v-level-list__status v-level-list__type-${showLevel}`}
                    ></div>
                );
            },
        },
        {
            field: 'catchmentArea',
            type: 'number',
            headerName: 'Einzugsgebiet',
            flex: 1,
            minWidth: 135,
            renderCell: (params) => {
                const catchmentArea = params.row.catchmentArea;
                return catchmentArea && catchmentArea > 0 ? catchmentArea.toLocaleString() + ' km\u00B2' : '';
            },
        },
        {
            field: 'mh1',
            type: 'number',
            headerName: 'MH1',
            flex: 1,
            minWidth: 70,
            sortable: false,
            renderCell: (params) => params.value && params.value + ' ' + params.row.unit,
        },
    ];

    // small list of columns which are not in the municipal view
    const notInMunicipalView = ['riverAreaName', 'mh1'];

    return (
        <div className="v-level-list container">
            <Grid container spacing={4}>
                <Grid item xs={12} md={9} style={{ paddingRight: '12px' }}>
                    <h1 className="h2">
                        Liste der {showLevel === 'municipal' ? 'Kommunalen Messstellen' : 'HVZ-Pegel'}
                    </h1>
                    {rows && (
                        <DataGrid
                            rowHeight={32}
                            headerHeight={32}
                            rows={rows}
                            columns={columns.filter(
                                (column) => !(showLevel === 'municipal' && notInMunicipalView.includes(column.field))
                            )}
                            onSortModelChange={handleSortModelChange}
                            disableColumnMenu
                            autoHeight
                            disableSelectionOnClick
                            rowsPerPageOptions={[]}
                            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                            initialState={{
                                sorting: {
                                    sortModel: defaultSortModel,
                                },
                            }}
                        />
                    )}
                </Grid>

                <Grid item xs={12} md={3}>
                    <div className="l-content-box">
                        <Button variant="contained" href={'/'} className="button">
                            Zurück zur Kartenansicht
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default VLevelList;
