import { Position } from 'geojson';
import { LatLng, Point, point as leafletPoint } from 'leaflet';
import * as proj4 from 'proj4leaflet';
import IConfig from '../models/config';
import IIndex from '../models/index';

/**
 * Create ETRS89 / UTM zone 32N projection to display stored shape data on map.
 * Resultion and origin are default values and will be overwritten by leaflet.
 * @see https://epsg.io/25832
 */
// No types available for proj4leaflet.
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
export const crs: { unproject: (coords: Point) => LatLng } = new proj4.CRS(
    'EPSG:25832',
    '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
    {
        resolutions: [256, 128],
        origin: [0, 0],
    }
);

/**
 * Unproject method for UTM32 grid.
 */
export const unproject = (coords: Position): LatLng => crs.unproject(leafletPoint(coords[0], coords[1]));

export const zoomToPointRadius = (zoom: number): number => {
    if (zoom >= 11) {
        return 14;
    }
    if (zoom >= 10) {
        return 9;
    }
    if (zoom >= 8.5) {
        return 5;
    }
    return 3;
};

export const checkTouchDevice = () => {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator['msMaxTouchPoints'] > 0;
};

type TTooltipDirection = 'top' | 'right' | 'bottom' | 'left';

export const calcTooltipDirection = (
    point: { x: number; y: number },
    bounds: { width: number; height: number },
    offset?: { x: number; y: number }
): TTooltipDirection => {
    const p = { ...point };
    if (offset) {
        p.x += offset.x;
        p.y += offset.y;
    }
    const directions: Array<{ direction: TTooltipDirection; distance: number }> = [
        {
            direction: 'bottom',
            distance: p.y,
        },
        {
            direction: 'top',
            distance: bounds.height - p.y,
        },
        {
            direction: 'right',
            distance: p.x,
        },
        {
            direction: 'left',
            distance: bounds.width - p.x,
        },
    ];
    return directions.reduce((min, d) => (min === null || d.distance < min.distance ? d : min), null).direction;
};

export const getIsSmallScreenSize = () => window.innerWidth < 568;

/**
 * Get alert region color or hatch if prealert and `hatchPreAlerts` isset.
 *
 * @param {number} alertRegionId
 * @param {IConfig | undefined} config
 * @param {IIndex | undefined} index
 * @param {boolean} hatchPreAlerts
 * @returns {string} The alert region color or hatch.
 */
export const getAlertRegionColor = (
    alertRegionId: number,
    config?: IConfig,
    index?: IIndex,
    hatchPreAlerts: boolean = false
): string => {
    const alertRegion = index?.alertregions[alertRegionId];
    if (config && alertRegion) {
        if (hatchPreAlerts && alertRegion.preAlert) {
            return `url(#Hatch_${alertRegion.alertClassId})`;
        }
        return config.alertclasses[alertRegion.alertClassId].color;
    }
    return '#93C66A';
};
