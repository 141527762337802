import { Chart, ChartEvent, Interaction, InteractionOptions } from 'chart.js';
import moment from 'moment-timezone';

/**
 * function for checking if array exists
 */
export const validateArray = <T>(data: Array<T>): Array<T> => (data && data.length ? data : []);

export const momentParse = (date: string | moment.Moment | null): moment.Moment | null => {
    return date ? moment.utc(date).tz('Europe/Berlin') : null;
};

export const momentFormatYear = (date: string | moment.Moment | null): string | null => {
    return momentParse(date)?.format('YYYY');
};

export const momentFormatDayOfMonth = (date: string | moment.Moment | null): string | null => {
    return momentParse(date)?.format('DD.MM.');
};

export const momentFormatDate = (date: string | moment.Moment | null): string | null => {
    return momentParse(date)?.format('DD.MM.YYYY');
};

export const momentFormatHour = (date: string | moment.Moment | null): string | null => {
    return momentParse(date)?.format('HH:mm');
};

export const momentFullFormat = (date: string | moment.Moment | null): string | null => {
    return momentParse(date)?.format('DD.MM.YYYY HH:mm');
};

export const formatNumber = (n: number, digits: number = 0) => {
    return new Intl.NumberFormat('de-DE', { minimumFractionDigits: digits }).format(n);
};

function padZero(str: string, len: number) {
    len = len || 2;
    const zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}

/**
 * Function for inverting Colors and get Contrast,
 * @params hex: hex-color, bw: boolean (true if color should be black or white)
 */
export const invertColor = (hex: string, bw: boolean) => {
    if (hex?.length) {
        if (hex?.indexOf('#') === 0) {
            hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex?.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex?.length !== 6) {
            throw new Error('Invalid HEX color.');
        }
        const r = parseInt(hex.slice(0, 2), 16);
        const g = parseInt(hex.slice(2, 4), 16);
        const b = parseInt(hex.slice(4, 6), 16);
        if (bw) {
            return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
        }

        const rr = (255 - r).toString(16);
        const gg = (255 - g).toString(16);
        const bb = (255 - b).toString(16);

        return '#' + padZero(rr, 2) + padZero(gg, 2) + padZero(bb, 2);
    }
    return '#FFFFFF';
};

/**
 * Define `nearestOffset` tooltip interaction mode to allow showing tooltips only if points exist within defined offset.
 */
declare module 'chart.js' {
    interface InteractionModeMap {
        nearestOffset: InteractionModeFunction;
    }
}
// Interaction.modes.
Interaction.modes.nearestOffset = (
    chart: Chart,
    e: ChartEvent,
    options: InteractionOptions & { nearestOffset?: number },
    useFinalPosition?: boolean
) => {
    const items = Interaction.modes.nearest(chart, e, options, useFinalPosition);
    const offset = options.nearestOffset;
    if (!items.length || (items.length === 1 && items[0].datasetIndex === 0) || typeof offset !== 'number') {
        return items;
    }

    return items.filter((item) => Math.floor(Math.abs(item.element.x - e.x)) <= offset);
};
