import { action, Action, createStore } from 'easy-peasy';
import { TSeverity } from '../models/misc';

export interface INotify {
    shown: boolean;
    message: string;
    severity: TSeverity;
}

export interface INotifyModel {
    item: INotify;
    set: Action<INotifyModel, INotify>;
}

export interface IAccModel {
    items: Array<IAcc>;
    register: Action<IAccModel, IAcc['id']>;
    closeOthers: Action<IAccModel, IAcc['id']>;
}

export interface IAcc {
    id: string;
    triggerClose: number;
}

export interface IDwdModel {
    url: string;
    update: Action<IDwdModel, string>;
}

export interface IselectedAlertRegion {
    id: number | undefined;
    setId: Action<IselectedAlertRegion, number>;
}

export interface IState {
    notify: INotifyModel;
    accordeon: IAccModel;
    dwdUrl: IDwdModel;
    tabVisible: boolean;
    updateTabVisible: Action<IState, boolean>;
    selectedAlertRegion: IselectedAlertRegion;
}

const store = createStore<IState>({
    notify: {
        item: {
            shown: false,
            message: '',
            severity: 'error',
        },
        set: action((state, payload: INotify) => {
            state.item = payload;
        }),
    },
    dwdUrl: {
        url: '',
        update: action((state, payload: string) => {
            state.url = payload;
        }),
    },
    tabVisible: true,
    updateTabVisible: action((state, payload: boolean) => {
        state.tabVisible = payload;
    }),
    selectedAlertRegion: {
        id: undefined,
        setId: action((state, id: number | undefined) => {
            state.id = id;
        }),
    },
    // accordeons: {
    //   accs: [],
    //   addAcc: action((state, item: IAccordeon) => {
    //     state.accs = []
    //   })
    // },

    accordeon: {
        items: [],
        register: action((state, id: IAcc['id']) => {
            if (!id) {
                return;
            }
            state.items.push({
                id,
                triggerClose: 0,
            });
        }),
        closeOthers: action((state, id: IAcc['id']) => {
            state.items.forEach((el) => {
                if (el.id !== id) {
                    el.triggerClose += 1;
                }
            });
        }),
    },
});

export default store;
