import { BarOptions, ChartOptions, Plugin } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar, Chart } from 'react-chartjs-2';
import { ILegend } from '../../../models/config';
import { IExtremeEventsData } from '../../../models/misc';
import {
    formatNumber,
    invertColor,
    momentFormatDate,
    momentFormatDayOfMonth,
    momentFormatYear,
    validateArray,
} from '../../../service/chartDataHandler';
import './EEventBarChart.scss';

interface IProps {
    extremeEventsData: Array<IExtremeEventsData> | null;
    legends: Record<string, ILegend>;
    threshold: Record<string, number>;
}
const EEventBarChart = (props: IProps) => {
    Chart.register(ChartDataLabels);
    Chart.register(annotationPlugin);

    const extremeEventsData: Array<IExtremeEventsData> | null = validateArray(props.extremeEventsData);
    const labels = extremeEventsData.map((extremeEvent: IExtremeEventsData) => [
        momentFormatDayOfMonth(extremeEvent.date),
        momentFormatYear(extremeEvent.date),
    ]);
    const values = extremeEventsData.map((extremeEvent: IExtremeEventsData) => extremeEvent.value);

    /**
     * Function analyzes the thresholds and assigns corresponding color values if the values exceed a certain threshold.
     * If value doesn't exceed a certain threshold, white color will be assigned by default.
     */
    const colors = extremeEventsData.map((extremeEvent: IExtremeEventsData) => {
        let maxThreshold = 0;
        let maxLegendId: string = null;
        if (props.threshold) {
            Object.keys(props.threshold).forEach((legendId) => {
                const threshold = props.threshold[legendId];
                if (maxThreshold <= threshold && threshold <= extremeEvent.value) {
                    maxThreshold = threshold;
                    maxLegendId = legendId;
                }
            });
        }
        return (maxLegendId && props.legends[maxLegendId]?.color) || '#fff';
    });

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Hochwasserereignis',
                data: values,
                backgroundColor: colors,
            },
        ],
    };

    const options: ChartOptions<'bar'> = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        scales: {
            y: {
                display: false,
                beginAtZero: true,
            },
            x: {
                grid: {
                    color: 'white',
                    lineWidth: 3,
                },
                ticks: {
                    font: {
                        weight: 'bold',
                        style: 'italic',
                    },
                },
            },
        },
        plugins: {
            tooltip: {
                mode: 'nearest',
                axis: 'x',
                position: 'average',
                callbacks: {
                    title: () => 'Hochwasserereignis',
                    label: (context) => {
                        const extremeEventData = extremeEventsData[context.dataIndex];
                        return `${momentFormatDate(extremeEventData.date)}: ${formatNumber(extremeEventData.value)} ${extremeEventData.dimension}`;
                    },
                },
                yAlign: 'bottom',
            },
            // @ts-expect-error Chart types do not correctly reflect the possibility to completely disable legends.
            legend: false,
            datalabels: {
                align: 'center',
                anchor: 'center',
                formatter: (val: number) => [
                    [formatNumber(val)],
                    [extremeEventsData[0].dimension === 'cm' ? extremeEventsData[0].dimension : 'm\u00B3/s'],
                ],
                labels: {
                    title: {
                        font: {
                            weight: 'bold',
                        },
                        // Wrong Chart.js type here context.dataset?.backgroundColor is in fact an array.
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        color: (context) => invertColor(context.dataset?.backgroundColor[context.dataIndex], true),
                    },
                },
            },
        },
        chartArea: {
            backgroundColor: 'rgb(240,240,240)',
        },
    };

    const plugins: Plugin<'bar'>[] = [
        {
            id: 'chart_accessibility_plugin',
            beforeDraw: (chart) => {
                // @ts-expect-error Wrong Chart.js type. Documentation states property exists.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (chart.config.options.chartArea?.backgroundColor) {
                    const ctx = chart.ctx;
                    const chartArea = chart.chartArea;
                    ctx.save();
                    // @ts-expect-error Wrong Chart.js type. Documentation states property exists.
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
                    ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
                    ctx.fillRect(
                        chartArea.left,
                        chartArea.top,
                        chartArea.right - chartArea.left,
                        chartArea.bottom - chartArea.top
                    );
                    ctx.restore();
                }
            },
        },
        {
            id: 'custom_canvas_background_color',
            beforeDraw: (chart) => {
                const ctx = chart.canvas.getContext('2d');
                ctx.save();
                ctx.globalCompositeOperation = 'destination-over';
                ctx.fillStyle = '#FFFFFF';
                ctx.fillRect(0, 0, chart.width, chart.height);
                ctx.restore();
            },
        },
    ];

    return (
        <div className="e-eventbar-chart">
            {extremeEventsData.length ? (
                <Bar
                    options={options as unknown as BarOptions}
                    data={data}
                    height={300}
                    plugins={plugins}
                    className="e-eventbar-chart__bar-chart"
                />
            ) : (
                <p className="e-eventbar-chart__not-available">Keine Daten zu Hochwasserereignissen verfügbar</p>
            )}
        </div>
    );
};

export default EEventBarChart;
