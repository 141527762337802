import EMap from '../../elements/map/EMap';
import LFooter from '../../layout/footer/LFooter';
import MStaticContent from '../../modules/static-content/MStaticContent';
import './VHome.scss';

const VHome = () => (
    <div className="v-home">
        <EMap>
            <section className="v-home__content">
                <div className="v-home__body">
                    <div className="container">
                        <MStaticContent />
                    </div>
                </div>

                <LFooter />
            </section>
        </EMap>
    </div>
);

export default VHome;
