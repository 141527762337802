import { Feature, Geometry } from 'geojson';
import { memo } from 'react';
import { GeoJSON } from 'react-leaflet';
import { TShapefileProperties } from '../../../service/shapefileHandler';
import { unproject } from '../../../utils/mapUtils';

/**
 * Line Component Leaflet Layer
 * used to display and handle riverLines
 */
interface IProps {
    data: Feature<Geometry, TShapefileProperties>;
}

/**
 * Define Weight for River Lines
 */
const getWeight = (data: Feature<Geometry, TShapefileProperties>) => {
    const order = data.properties.ORDER;
    if (order === 1) {
        return 3;
    }
    if (order === 2) {
        return 1.75;
    }
    if (order >= 3) {
        return 0.5;
    }
    return 1.75;
};

// Project approved
// eslint-disable-next-line @typescript-eslint/naming-convention
const EMap__RiverPolygon = ({ data }: IProps) => {
    /* Render river as a GeoJSON Object with weight depending on rivers order. */
    /* hide rivers of order 2 or 3 on lower zoom level */
    // if (props.data.properties.ORDER == 2 && zoom < 10) return null
    // if (props.data.properties.ORDER == 3 && zoom <= 10) return null
    const weight = getWeight(data);
    const opts = {
        data,
        coordsToLatLng: unproject,
        // noClip: true,
        // shadowPane: '',
        interactive: false,
        // className: 'polygon',
        style: {
            color: '#5c7ea2',
            weight,
        },
    };

    return <GeoJSON {...opts} />;
};

export default EMap__RiverPolygon;
// Project approved
// eslint-disable-next-line @typescript-eslint/naming-convention
export const EMap__MemoizedRiverPolygon = memo(EMap__RiverPolygon);
