import { useEffect, useState } from 'react';

function useResize(): { width: number; height: number } {
    const [size, setSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(
        () => {
            const listener = () =>
                setSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            window.addEventListener('resize', listener);

            return () => {
                window.removeEventListener('resize', listener);
            };
        },
        // Inabilty to resolve window as react dependency.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [window.innerWidth, window.innerHeight]
    );

    return size;
}

export { useResize };
