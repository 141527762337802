import { QueryClient, useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';
import apiPaths from '../config/apiPaths';
import queryConfig from '../config/query';
import IIndex, { TStatusReportGlobal } from '../models';
import IConfig from '../models/config';
import { IMeasurementSiteDetails, IRiverAreaDetails } from '../models/misc';
import errorHandler from './errorHandler';
import apiClient, { validateResponse } from './httpService';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // Only fetch things ONCE or when manually triggered/configured.
            staleTime: queryConfig.defaultStaleTime,
        },
    },
});

/**
 * @see {@link useConfigRequest}
 */
export const configRequestQueryOptions: UseQueryOptions<IConfig> = {
    queryKey: ['config'],
    queryFn: async () => {
        try {
            const res = await apiClient.get<IConfig>(apiPaths.config);
            return validateResponse(res);
        } catch (err) {
            console.error(err);
            errorHandler(err);
        }
        return null;
    },
    ...queryConfig.configRequest,
};

/**
 * Get config request data either from cache, if it was fetched before else
 * asynchronously from remote API. After backend fetch finishes a rerendering is
 * triggered to give access to the cached result.
 *
 * @returns {UseQueryResult<IConfig, Error>} Tanstack query result object
 *  wrapping config request response. For more information and additional
 *  properties @see https://tanstack.com/query/latest/docs/framework/react/reference/useQuery
 */
export const useConfigRequest = () => useQuery(configRequestQueryOptions);

/**
 * @see {@link useIndexRequest}
 */
export const indexRequestQueryOptions: UseQueryOptions<IIndex> = {
    queryKey: ['index'],
    queryFn: async () => {
        try {
            const res = await apiClient.get<IIndex>(apiPaths.index);
            return validateResponse(res);
        } catch (err) {
            console.error(err);
            errorHandler(err);
        }
        return null;
    },
    ...queryConfig.indexRequest,
};

/**
 * Get config request data either from cache, if it was fetched before else
 * asynchronously from remote API. After backend fetch finishes a rerendering is
 * triggered to give access to the cached result.
 *
 * @returns {UseQueryResult<IIndex, Error>} Tanstack query result object
 *  wrapping config request response. For more information and additional
 *  properties @see https://tanstack.com/query/latest/docs/framework/react/reference/useQuery
 */
export const useIndexRequest = () => useQuery(indexRequestQueryOptions);

/**
 * @see {@link useRiverAreaDetailRequest}
 */
export const riverAreaDetailRequestQueryOptions = (riverAreaId?: number): UseQueryOptions<IRiverAreaDetails> => ({
    queryKey: ['riverAreaDetails', riverAreaId],
    queryFn: async () => {
        try {
            const path = apiPaths.riverArea;
            const res = await apiClient.get<IRiverAreaDetails>(path + '/' + riverAreaId);
            return validateResponse(res);
        } catch (err) {
            console.error(err);
            errorHandler(err);
        }
        return null;
    },
    enabled: !!riverAreaId,
    ...queryConfig.riverAreaDetailRequest,
});

/**
 * Get river area detail request data for single river area either from cache,
 * if it was fetched before else asynchronously from remote API. After backend
 * fetch finishes a rerendering is triggered to give access to the cached result.
 *
 * @param {number} [riverAreaId] Id of river area to fetch. If not given, method will
 *  return immediately without performing any ajax call or cache test with
 *  `result.data` set to `undefined`.
 * @returns {UseQueryResult<IRiverAreaDetails, Error>} Tanstack query result object
 *  wrapping river area request response. For more information and additional
 *  properties @see https://tanstack.com/query/latest/docs/framework/react/reference/useQuery
 */
export const useRiverAreaDetailRequest = (riverAreaId?: number) =>
    useQuery(riverAreaDetailRequestQueryOptions(riverAreaId));

/**
 * @see {@link useMeasurementSiteDetailRequest}
 */
export const useMeasurementSiteDetailRequestOptions = (
    msNumber?: number
): UseQueryOptions<IMeasurementSiteDetails> => ({
    queryKey: ['measurementSiteDetails', msNumber],
    queryFn: async () => {
        try {
            const path = apiPaths.measurmentSite;
            const res = await apiClient.get<IMeasurementSiteDetails>(path + '/' + msNumber);
            return validateResponse(res);
        } catch (err) {
            console.error(err);
            errorHandler(err);
        }
        return null;
    },
    enabled: !!msNumber,
    ...queryConfig.measurementSiteDetail,
});

/**
 * Get measurement site detail request data for single measurement site either
 * from cache, if it was fetched before else asynchronously from remote API.
 * After backend fetch finishes a rerendering is triggered to give access to the
 * cached result.
 *
 * @param {number} [msNumber] Measurement site number. If not given, method will
 *  return immediately without performing any ajax call or cache test with
 *  `result.data` set to `undefined`.
 * @returns {UseQueryResult<IMeasurementSiteDetails, Error>} Tanstack query result object
 *  wrapping measurement site request response. For more information and
 *  additional properties @see https://tanstack.com/query/latest/docs/framework/react/reference/useQuery
 */
export const useMeasurementSiteDetailRequest = (msNumber?: number) =>
    useQuery(useMeasurementSiteDetailRequestOptions(msNumber));

/**
 * @see {@link useStatusReportGlobalRequest}
 */
export const statusReportGlobalRequestQueryOptions: UseQueryOptions<TStatusReportGlobal> = {
    queryKey: ['statusReport'],
    queryFn: async () => {
        try {
            const res = await apiClient.get<TStatusReportGlobal>(apiPaths.statusReport);
            return validateResponse(res);
        } catch (err) {
            console.error(err);
            errorHandler(err);
        }
        return null;
    },
    ...queryConfig.statusReportGlobalRequest,
};

/**
 * Get global status report request data either from cache, if it was fetched
 * before else asynchronously from remote API. After backend fetch finishes a
 * rerendering is triggered to give access to the cached result.
 *
 * @returns {UseQueryResult<TStatusReportGlobal, Error>} Tanstack query result
 *  object wrapping config request response. For more information and additional
 *  properties @see https://tanstack.com/query/latest/docs/framework/react/reference/useQuery
 */
export const useStatusReportGlobalRequest = () => useQuery(statusReportGlobalRequestQueryOptions);

/**
 * Get partial either from cache, if it was fetched before else asynchronously
 * from remote API. After backend fetch finishes a rerendering is triggered to
 * give access to the cached result.
 *
 * @param {fileName} string The fileName of the partial to fetch.
 * @param {path} [string] Optional path which will be prepended to fileName. If
 *  not set default partial path is used.
 * @returns {UseQueryResult<string, Error>} Tanstack query result object
 *  wrapping measurement site request response. For more information and
 *  additional properties @see https://tanstack.com/query/latest/docs/framework/react/reference/useQuery
 */
export const usePartialRequest = (fileName: string, path?: string) =>
    useQuery({
        queryKey: ['partial', fileName, path],
        queryFn: async () => {
            const res = await axios.get<string>((path || apiPaths.partials) + fileName + '.phtml');
            return validateResponse(res);
        },
    });
