import axios from 'axios';
import store from '../store/store';

/**
 * Globally handles wanted or unwanted network errors.
 */
const errorHandler = (err: unknown): void => {
    let errMessage = 'No valid server response';
    if (axios.isAxiosError(err)) {
        /**
         * Handle Error Response Codes
         */
        // TODO: adapt to api error handling
    } else {
        /**
         * Handle unknown network errors
         */
        console.warn(errMessage, err);
    }
    if (typeof err === 'object' && 'message' in err && typeof err.message === 'string') {
        errMessage = err.message;
    }
    store.getActions().notify.set({
        shown: true,
        message: errMessage,
        severity: 'error',
    });
};

export default errorHandler;
