import { ReactNode } from 'react';
import { IStatusReport } from '../../../models';
import { momentFullFormat } from '../../../service/chartDataHandler';
import './VStatusReport.scss';

interface IProps {
    statusReport: IStatusReport;
    id?: string;
    title: string;
    level: string;
    children?: ReactNode;
}

const EStatusReportLine = ({ id, level, statusReport, title, children }: IProps) => {
    const headline =
        level === 'root' ? <h2>{title}</h2> : level === 'riverarea' ? <h3>Flussgebiet {title}</h3> : <h3>{title}</h3>;

    // const id = statusReport.riverAreaId
    return (
        <div className={'v-status-report__' + level} id={id}>
            <div className="v-status-report__head">
                {headline}

                {statusReport.date && <p>vom {momentFullFormat(statusReport.date)} Uhr</p>}
            </div>

            {statusReport.info && (
                <div className="v-status-report__info" dangerouslySetInnerHTML={{ __html: statusReport.info }}></div>
            )}

            {children}
        </div>
    );
};

export default EStatusReportLine;
