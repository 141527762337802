import axios, { AxiosInstance, AxiosResponse } from 'axios';
import apiPaths from '../config/apiPaths';
import errorHandler from './errorHandler';

/**
 * Create a new axios instance with default configuration for all requests
 */
const apiClient: AxiosInstance = axios.create({
    baseURL: apiPaths.base,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

export default apiClient;

/**
 * Validate server response
 */
export const validateResponse = <DataType>(res: AxiosResponse<DataType>): DataType | null => {
    const responseData = res.data;
    // TODO Check this! We have no request returning an object with property "error".
    // Additionally `errorHandler()` is not doing anything with `responseData.error` therefore this check here is superfluous.
    if (!responseData || (typeof responseData === 'object' && 'error' in responseData && responseData.error)) {
        // TODO: adapt to api error handling
        errorHandler(responseData);
        return null;
    }
    return responseData;
};
