import { MouseEvent, useEffect, useState } from 'react';
import AccordeonButtonIcon from '../../../assets/images/icon-accordeon-button.svg';
import './EAccordeon.scss';

interface IProps {
    externalIsOpen?: boolean; // initial isOpen state controlled from outside of this component
    children: {
        heading: JSX.Element;
        content: JSX.Element;
    };
}

const EAccordeon = (props: IProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(true);

    useEffect(() => {
        if (props.externalIsOpen === undefined) {
            return;
        }
        setIsOpen(props.externalIsOpen);
    }, [props.externalIsOpen]);

    // checks if event (click) is a link or somethin else - if its a link then set target if not then search in parent tree otherwise set undefined
    const handleDescriptionClick = (event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        const eventTarget = event.target as HTMLDivElement;
        const closestAnchor = eventTarget.closest('a');

        if (closestAnchor) {
            const origin = window.location.origin;
            const href = closestAnchor.href;

            // check if href is an internel link of the site's origin
            if (href.startsWith(origin)) {
                window.location.href = href;
            } else {
                window.open(href);
            }
        }
    };

    return (
        <div className={`e-accordeon${isOpen ? ' e-accordeon--open' : ''}`}>
            <div className="e-accordeon__heading" onClick={() => setIsOpen(!isOpen)}>
                <div className="e-accordeon__heading-inner">{props.children.heading}</div>
                <img src={AccordeonButtonIcon} aria-hidden="true" />
            </div>
            <div className="e-accordeon__content-wrapper">
                <div className="e-accordeon__content">
                    <div className="e-accordeon__content-inner" onClick={handleDescriptionClick}>
                        {props.children.content}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EAccordeon;
