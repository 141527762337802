import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useQueries } from '@tanstack/react-query';
import { configRequestQueryOptions, indexRequestQueryOptions } from '../../../service/globalService';
import Hatch from '../../../utils/Hatch';
import EHelpIcon from '../../elements/help-icon/EHelpIcon';
import EWarningsTableRow from './EWarningsTableRow';
import './VWarnings.scss';

const VWarningsPage = () => {
    const [{ data: config }, { data: index }] = useQueries({
        queries: [configRequestQueryOptions, indexRequestQueryOptions],
    });

    return (
        <div className="container v-warnings">
            {config && index && (
                <>
                    <div className="e-map__hatches-prerender">
                        {config.alertclasses &&
                            Object.keys(config.alertclasses).map((id) => {
                                const alertClass = config.alertclasses[id];
                                return (
                                    <Hatch
                                        color={alertClass.color}
                                        stripeColor={alertClass.stripeColor}
                                        alertClassId={id}
                                        key={id}
                                    />
                                );
                            })}
                    </div>

                    <h1>
                        Warnungen <EHelpIcon hash="warnungen" label="Erläuterungen zu den Warnungen" />
                    </h1>

                    <p className="v-warnings__description">
                        Ist für eine Warnregion aufgrund von Modellberechnungen oder Pegelmessungen ein Hochwasser
                        vorhersagbar, dann erhält dieses Warngebiet eine von grün abweichende Warnfarbe. Nachfolgend
                        sind alle Warngebiete mit der jeweils aktuellen Warnstufe aufgelistet.
                        <br /> <br />
                        Weitere aktuelle Informationen durch Auswahl einer Warnregion auf der <a href="/">Warnkarte</a>.
                    </p>

                    <TableContainer className="v-warnings__table">
                        <Table>
                            <TableHead className="v-warnings__table-head">
                                <TableRow className="v-warnings__table-row">
                                    <TableCell className="v-warnings__table-cell heading">Warnregion</TableCell>
                                    <TableCell className="v-warnings__table-cell heading">Warnstufe</TableCell>
                                    <TableCell className="v-warnings__table-cell heading">Bedeutung</TableCell>
                                    <TableCell className="v-warnings__table-cell heading">Gültig ab</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {Object.keys(config.alertregions).map((alertRegionId) => (
                                    <EWarningsTableRow
                                        key={alertRegionId}
                                        alertRegionId={alertRegionId}
                                        config={config}
                                        index={index}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </div>
    );
};

export default VWarningsPage;
