import L, { DomEvent, LeafletMouseEvent } from 'leaflet';
import { useCallback, useRef } from 'react';
import { throttle } from './helpers';

const useEventForwarder = (throttleMs: number) => {
    const layerHover = useRef<Element>(null);

    const handleMouseMove = useCallback((event: LeafletMouseEvent) => {
        // get the target pane
        const currentTarget = event.originalEvent.target as HTMLElement;

        // hide the target node
        const removed = { node: currentTarget, pointerEvents: currentTarget.style.pointerEvents };
        currentTarget.style.pointerEvents = 'none';

        // attempt to grab the next layer below
        const nextTarget = document.elementFromPoint(event.originalEvent.clientX, event.originalEvent.clientY);

        // we keep drilling down until we get stopped,
        // or we reach the map container itself
        if (
            nextTarget &&
            nextTarget.nodeName.toLowerCase() !== 'body' &&
            nextTarget.classList.value.indexOf('leaflet-container') === -1
        ) {
            const isCanvas = nextTarget.nodeName.toLowerCase() === 'canvas';
            const eventType = isCanvas ? 'mousemove' : 'mouseover';
            const forwardEvent = new MouseEvent(eventType, event.originalEvent);

            nextTarget.dispatchEvent(forwardEvent);
            // @ts-expect-error Wrong type here. The property exists.
            if (forwardEvent._stopped) {
                if (layerHover.current && layerHover.current !== nextTarget) {
                    layerHover.current.dispatchEvent(new MouseEvent('mouseout', event.originalEvent));
                }
                layerHover.current = nextTarget;
            }
        }

        // restore pointerEvents
        removed.node.style.pointerEvents = removed.pointerEvents;
    }, []);

    const handleClick = useCallback((event: LeafletMouseEvent) => {
        const currentTarget = event.originalEvent.target as HTMLElement;
        const targetStore = { node: currentTarget, pointerEvents: currentTarget.style.pointerEvents };

        currentTarget.style.pointerEvents = 'none';

        const nextTarget = document.elementFromPoint(event.originalEvent.clientX, event.originalEvent.clientY);

        if (
            nextTarget &&
            nextTarget.nodeName.toLowerCase() !== 'body' &&
            nextTarget.classList.value.indexOf('leaflet-container') === -1
        ) {
            const forwardEvent = new MouseEvent(event.originalEvent.type, event.originalEvent);
            const stopped = !nextTarget.dispatchEvent(forwardEvent);
            // @ts-expect-error Wrong type here. The property exists.
            if (stopped || forwardEvent._stopped) {
                DomEvent.stop(event);
            }
        }

        targetStore.node.style.pointerEvents = targetStore.pointerEvents;
    }, []);

    const initEventForwarder = useCallback(
        (map: L.Map) => {
            if (!map) {
                return undefined;
            }

            map.on('mousemove', throttle(handleMouseMove, throttleMs), this);
            map.on('click', handleClick, this);

            // TODO Check this! Invalid use of `useCallback()` by returning cleanup method - this is only supported for `useEffect()`-
            return () => {
                map.off('mousemove', throttle(handleMouseMove, throttleMs), this);
                map.off('click', handleClick, this);
            };
        },
        [handleClick, handleMouseMove, throttleMs]
    );

    return {
        initEventForwarder,
    };
};

export default useEventForwarder;
