import { Feature, Geometry } from 'geojson';
import { GeoJSONOptions, LeafletEventHandlerFnMap, LeafletMouseEvent, Path, PathOptions } from 'leaflet';
import { GeoJSON, Tooltip } from 'react-leaflet';
import { TShapefileProperties } from '../../../service/shapefileHandler';
import { checkTouchDevice, unproject } from '../../../utils/mapUtils';
import './EMap__NeighbourCountryPolygon.scss';
import './EMap__Polygon.scss';

/**
 * Polygon Component Leaflet Layer
 * used to display and handle neighbourcountries
 */
interface IProps {
    data: Feature<Geometry, TShapefileProperties>;
    name: string;
    url: string;
}

const isTouchDevice = checkTouchDevice();

function layerMouseOverHandler(e: LeafletMouseEvent) {
    const eventTarget = e.originalEvent.target as HTMLElement;
    if (eventTarget.tagName === 'CANVAS') {
        // @ts-expect-error Wrong type here. The property exists.
        e.originalEvent._stopped = true;

        // Obviously `Path` is the wrong type here, but GeoJSON does not have a Layer type and `Path` fits best.
        const layer = e.layer as Path;
        layer.options.weight = 2;
        layer.redraw();
    }
}

function layerMouseOutHandler(e: LeafletMouseEvent) {
    const eventTarget = e.originalEvent.target as HTMLElement;
    if (eventTarget.tagName === 'CANVAS') {
        // Obviously `Path` is the wrong type here, but GeoJSON does not have a Layer type and `Path` fits best.
        const layer = e.layer as Path;
        const layerOptions = layer.options as GeoJSONOptions<Path> & PathOptions;
        const weight = (layerOptions.style as PathOptions).weight;
        layer.options.weight = weight;
        layer.redraw();
    }
}

// Project approved
// eslint-disable-next-line @typescript-eslint/naming-convention
const EMap__NeighbourCountryPolygon = ({ data, name, url }: IProps) => {
    const eventHandlers: LeafletEventHandlerFnMap = {
        // Because react leaflet seems to ignore Tooltip.position prop, we have to set position on open.
        tooltipopen: (e) => {
            if (data.properties.LABEL_X && data.properties.LABEL_Y) {
                // e.tooltip.setOpacity(1)
                e.tooltip.setLatLng(unproject([data.properties.LABEL_X, data.properties.LABEL_Y]));
                e.tooltip.update();
            }
        },
        mouseover: !isTouchDevice ? layerMouseOverHandler : undefined,
        mouseout: !isTouchDevice ? layerMouseOutHandler : undefined,
    };
    return (
        <GeoJSON
            data={data}
            coordsToLatLng={unproject}
            // noClip={true}
            // shadowPane=""
            style={{
                weight: 1,
                fillColor: 'white',
                color: '#999',
                fillOpacity: 1,
                className: 'polygon e-map__neighbour-countries',
            }}
            interactive={true}
            eventHandlers={eventHandlers}
        >
            <Tooltip
                opacity={1}
                pane="neighbourcountries"
                className="e-map__neighbour-countries__tooltip"
                direction="center"
                interactive={true}
                // This is not working at all.
                // position={[0, 0]}
                permanent
            >
                <a href={url} target="_blank" rel="noreferrer">
                    {name}
                </a>
            </Tooltip>
        </GeoJSON>
    );
};

export default EMap__NeighbourCountryPolygon;
