import { CircleMarkerProps, createPathComponent, LeafletContextInterface, updateCircle } from '@react-leaflet/core';
import { Canvas, CircleMarker, Point } from 'leaflet';

/**
 * This marker extends the default CircleMarker and just changes its appearance
 * to a square. From a human point of view, circles and squares with the same
 * radius do not have the same size. Therefore we scale this marker by a certain
 * scalefactor to make circles and squares visually look the same size.
 */
const MARKER_SCALING_FACTOR = 0.66;

Canvas.include({
    /**
     * Draw municipal marker on map canvas.
     *
     * @param {MunicipalMarkerElement} layer
     */
    _updateCustomMunicipalMarker: function (layer: MunicipalMarkerElement) {
        // eslint-disable-next-line @typescript-eslint/dot-notation, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        if (!this['_drawing'] || layer['_empty']()) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/dot-notation, @typescript-eslint/no-unsafe-assignment
        const p: Point = layer['_point'];
        // eslint-disable-next-line @typescript-eslint/dot-notation, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        const ctx: CanvasRenderingContext2D = this['_ctx'];
        const width = Math.max(Math.round(layer.options.radius) * 2 * MARKER_SCALING_FACTOR, 1);

        ctx.strokeStyle = 'black';
        ctx.fillStyle = layer.options.fillColor || 'black';

        ctx.lineWidth = 1.0;

        ctx.beginPath();

        ctx.rect(p.x - width / 2, p.y - width / 2, width, width);

        ctx.fill();
        ctx.stroke();
    },
});

export class MunicipalMarkerElement extends CircleMarker {
    // TODO Change click area like described here https://stackoverflow.com/questions/56067809/markers-extended-from-circlemarker-how-to-change-the-click-area

    _updatePath() {
        // Render square.
        // eslint-disable-next-line @typescript-eslint/dot-notation, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        this['_renderer']['_updateCustomMunicipalMarker'](this);
    }
}

const createLeafletElement = (
    { center, children: _c, ...options }: CircleMarkerProps,
    ctx: LeafletContextInterface
) => {
    const instance = new MunicipalMarkerElement(center, options);
    return { instance, context: { ...ctx, overlayContainer: instance } };
};

const MunicipalMarker = createPathComponent(createLeafletElement, updateCircle);

export default MunicipalMarker;
