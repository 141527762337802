import { UseQueryOptions } from '@tanstack/react-query';
import IIndex, { TStatusReportGlobal } from '../models';
import IConfig from '../models/config';
import { IMeasurementSiteDetails, IRiverAreaDetails } from '../models/misc';

const queryConfig = {
    /**
     * The default time in milliseconds after data is considered stale and will
     * be refetched on enabled triggers like onMount, onConnect, onWindowFocus.
     * If set to Infinity, the data will never be considered stale.
     * Defaults to 24 hours.
     */
    defaultStaleTime: 24 * 60 * 60 * 1000,

    /**
     * Config request configuration.
     */
    configRequest: <UseQueryOptions<IConfig>>{
        /**
         * Refetch automatically every 10 minutes.
         */
        refetchInterval: 10 * 60 * 1000,

        /**
         * Because the refetch timer is stopped when window loses focus, set
         * stale time to equal refetch interval to trigger refetch when window
         * gains focus again.
         */
        staleTime: 10 * 60 * 1000,
    },

    /**
     * Index request configuration.
     */
    indexRequest: <UseQueryOptions<IIndex>>{
        /**
         * Refetch automatically every 5 minutes.
         */
        refetchInterval: 5 * 60 * 1000,

        /**
         * Because the refetch timer is stopped when window loses focus, set
         * stale time to equal refetch interval to trigger refetch when window
         * gains focus again.
         */
        staleTime: 5 * 60 * 1000,
    },

    /**
     * Global status report request configuration.
     */
    statusReportGlobalRequest: <UseQueryOptions<TStatusReportGlobal>>{
        /**
         * Refetch automatically every 5 minutes.
         */
        refetchInterval: 5 * 60 * 1000,

        /**
         * Because the refetch timer is stopped when window loses focus, set
         * stale time to equal refetch interval to trigger refetch when window
         * gains focus again.
         */
        staleTime: 5 * 60 * 1000,
    },

    /**
     * River area detail request configuration.
     */
    riverAreaDetailRequest: <UseQueryOptions<IRiverAreaDetails>>{
        /**
         * Refetch automatically every 5 minutes.
         */
        refetchInterval: 5 * 60 * 1000,

        /**
         * Because the refetch timer is stopped when window loses focus, set
         * stale time to equal refetch interval to trigger refetch when window
         * gains focus again.
         */
        staleTime: 5 * 60 * 1000,
    },

    /**
     * Measurement site detail request configuration.
     */
    measurementSiteDetail: <UseQueryOptions<IMeasurementSiteDetails>>{
        /**
         * Refetch automatically every 5 minutes.
         */
        refetchInterval: 5 * 60 * 1000,

        /**
         * Because the refetch timer is stopped when window loses focus, set
         * stale time to equal refetch interval to trigger refetch when window
         * gains focus again.
         */
        staleTime: 5 * 60 * 1000,
    },

    /**
     * "Deutscher Wetterdienst"-Overview image request configuration.
     */
    dwdImageRequest: <UseQueryOptions<string>>{
        /**
         * Refetch automatically every 5 minutes.
         */
        refetchInterval: 5 * 60 * 1000,

        /**
         * Because the refetch timer is stopped when window loses focus, set
         * stale time to equal refetch interval to trigger refetch when window
         * gains focus again.
         */
        staleTime: 5 * 60 * 1000,
    },
};

export default queryConfig;
