import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowUp';
import { ReactNode, TouchEvent, useState } from 'react';
import './MContentBox.scss';

/**
 * Polygon Component Leaflet Layer
 * used to display and handle lakes
 */
interface IProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    children: ReactNode;
}

const MContentBox = (props: IProps) => {
    const [touchStart, setTouchStart] = useState<number>(null);
    const [touchEnd, setTouchEnd] = useState<number>(null);

    // The required distance between touchStart and touchEnd to be detected as a swipe.
    const minSwipeDistance = 50;

    const toggleOpenState = () => {
        props.setIsOpen(!props.isOpen);
    };

    const touchEvents = {
        onTouchStart: (e: TouchEvent<HTMLDivElement>) => {
            setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
            setTouchStart(e.targetTouches[0].clientY);
        },

        onTouchMove: (e: TouchEvent<HTMLDivElement>) => setTouchEnd(e.targetTouches[0].clientY),

        onTouchEnd: () => {
            if (!touchStart || !touchEnd) {
                return;
            }
            const distance = touchStart - touchEnd;
            const isSwipeUp = distance > minSwipeDistance;
            const isSwipeDown = distance < -minSwipeDistance;
            if (isSwipeUp || isSwipeDown) {
                if ((!props.isOpen && isSwipeUp) || (props.isOpen && isSwipeDown)) {
                    toggleOpenState();
                }
            }
        },
    };

    return (
        <div className={'m-content-box' + (props.isOpen ? ' m-content-box--open' : '')}>
            <div
                className="m-content-box__toggle-button"
                tabIndex={1}
                onClick={toggleOpenState}
                onKeyPress={toggleOpenState}
                {...touchEvents}
            >
                <KeyboardArrowDownIcon />
            </div>
            <div className="m-content-box__inner">{props.children}</div>
        </div>
    );
};

export default MContentBox;
