import { localService } from '../service/storageHandler';

// import shapefile from 'shapefile';
import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import { read } from 'shapefile';

export type TShapefileType = 'alertRegions' | 'riverLines' | 'stations' | 'lakes' | 'neighbourcountry' | 'riverAreas';
export type TShapefileHashType =
    | 'alertRegionsHash'
    | 'riverLinesHash'
    | 'stationsHash'
    | 'riverAreasHash'
    | 'lakesHash'
    | 'neighbourcountryHash';

const sharedPath = '/static/shared';

const filePaths: Record<TShapefileType, string> = {
    alertRegions: sharedPath + '/shp/alertregion/warnregionen.shp',
    riverLines: sharedPath + '/shp/riverline/gewaesser.shp',
    riverAreas: sharedPath + '/shp/riverarea/flussgebiete.shp',
    lakes: sharedPath + '/shp/lake/flaechengewaesser.shp',
    stations: sharedPath + '/shp/measurementsite/pegelorte.shp',
    neighbourcountry: sharedPath + '/shp/neighbourcountry/nachbarland.shp',
};

export type TShapefileProperties = GeoJsonProperties & {
    DRAW_ORDER?: number;
    ID?: number;
    LABEL_X?: number;
    LABEL_Y?: number;
    NAME?: string;
    ORDER?: number;
    TYPE?: number;
    URL?: string;
};

/**
 * Fetch and parse shapefile into FeatureCollection.
 *
 * @param {string} name
 * @param {string} hashValue
 * @returns {Promise<FeatureCollection<Geometry, TShapefileProperties>>}
 */
export async function getShapeFileData(
    name: TShapefileType,
    hashValue: string
): Promise<FeatureCollection<Geometry, TShapefileProperties>> {
    // Check local storage cache.
    const hashName = `${name}Hash`;
    const storageResult = localService.get<FeatureCollection>(name);
    const hashResult = localService.get<string>(hashName);
    if (
        storageResult &&
        hashResult === hashValue &&
        // Ensure local storage returns a feature collection.
        storageResult.type &&
        storageResult.type === 'FeatureCollection'
    ) {
        return storageResult;
    }

    // Delete current local storage entry, if any.
    localService.remove(name);
    localService.remove(hashName);

    // Fetch shapefile and store in local storage cache.
    try {
        const collection = await read(filePaths[name], undefined, { encoding: 'UTF-8' });
        localService.set(name, collection);
        localService.set(hashName, hashValue);
        return collection;
    } catch (error) {
        console.error(error);
        return Promise.reject(error);
    }
}
