import { ExpandMore } from '@material-ui/icons';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import routes from '../../../config/routes';
import IConfig from '../../../models/config';
import ErrorBoundary from '../../elements/error-boundary/EErrorBoundary';
import './MMainMenu.scss';

interface INavItemProps {
    closeMenu: () => void;
    label: string;
    path?: string;
    tabIndex: number;
}

/**
 * Utility component to render optionally expandable navigation items.
 */
const NavItem = ({ closeMenu, label, path, tabIndex }: INavItemProps) => (
    <div className="nav-item">
        <Link to={path} className="nav-link" onClick={closeMenu} tabIndex={tabIndex}>
            {label}
        </Link>
    </div>
);

interface INavItem {
    label: string;
    path?: string;
    items?: INavItem[];
}

interface IProps {
    isOpen: boolean;
    closeMenu: () => void;
    config?: IConfig | null;
}

const MMainMenu = ({ config, closeMenu, isOpen }: IProps) => {
    const [expanded, setExpanded] = useState<string | false>(false);
    const tabIndex = isOpen ? 1 : -1;

    const hasMunicipalMeasurementSites = config.hasMunicipalMeasurementSites;

    useEffect(() => {
        // Close accordions when menu closes.
        if (!isOpen) {
            setExpanded(false);
        }
    }, [isOpen]);

    // Define menu items.
    const navItems: INavItem[] = [
        routes.home,
        { label: 'Berichte', items: [routes.statusReport, routes.weatherReport] },
        routes.warnings,
        {
            label: 'Flussgebiete',
            items:
                config &&
                Object.entries(config.riverareas)
                    .filter(([raId]) => raId !== '8888888888')
                    .map(([, riverArea]) => ({
                        label: riverArea.name,
                        path: '/flussgebiet/' + riverArea.slug,
                    })),
        },
        hasMunicipalMeasurementSites
            ? {
                  label: 'Pegellisten',
                  items: [
                      { label: 'HVZ-Pegel', path: routes.levelList.path + '/land' },
                      {
                          label: 'Kommunale Messstellen',
                          path: routes.levelList.path + '/kommunal',
                      },
                  ],
              }
            : { label: 'Pegelliste', path: routes.levelList.path + '/land' },
        { label: 'Karten', items: [{ label: 'Hochwasserwarnkarte', path: routes.home.path }, routes.rainfall] },

        {
            label: 'Weitere Infos',
            items: [
                routes.news,
                routes.help,
                routes.faq,
                routes.sources,
                routes.levelReportingMarks,
                routes.publications,
                routes.links,
                routes.contact,
            ],
        },
    ];

    return (
        <div className={'m-main-menu' + (isOpen ? ' m-main-menu--open' : '')} data-testid="main-menu">
            <div className="container">
                <div className="m-main-menu__main-area">
                    <nav className="m-main-menu__nav-wrap">
                        <div className="m-main-menu__navigation">
                            {navItems.map((navItem, i) =>
                                // Handle expandable nav items.
                                navItem.items?.length ? (
                                    <Accordion
                                        expanded={expanded === navItem.label}
                                        className="nav-item"
                                        onChange={(_, isExpanded: boolean) =>
                                            setExpanded(isExpanded ? navItem.label : false)
                                        }
                                        key={i}
                                    >
                                        <AccordionSummary expandIcon={<ExpandMore />} tabIndex={tabIndex}>
                                            <span className="nav-link">{navItem.label}</span>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {navItem.items.map((subNavItem, j) => (
                                                <NavItem
                                                    label={subNavItem.label}
                                                    path={subNavItem.path}
                                                    closeMenu={closeMenu}
                                                    tabIndex={tabIndex}
                                                    key={j}
                                                />
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                ) : (
                                    // Handle single line nav items.
                                    <NavItem
                                        label={navItem.label}
                                        path={navItem.path}
                                        closeMenu={closeMenu}
                                        tabIndex={tabIndex}
                                        key={i}
                                    />
                                )
                            )}
                        </div>
                    </nav>

                    <div className="m-main-menu__sidebar-wrap">
                        <div className="sidebar-area sidebar-link-area">
                            <h2>Direktlinks</h2>
                            <div className="sidebar-area__links-wrap">
                                <ErrorBoundary>
                                    {Object.entries(config.riverareas)
                                        .filter(([raId]) => raId !== '8888888888')
                                        .map(([, riverArea], i) => (
                                            <Link
                                                to={'/flussgebiet/' + riverArea.slug}
                                                className="sidebar-link"
                                                tabIndex={tabIndex}
                                                onClick={closeMenu}
                                                key={i}
                                            >
                                                {riverArea.name}
                                            </Link>
                                        ))}
                                </ErrorBoundary>
                            </div>
                        </div>
                        <div className="sidebar-area sidebar-link-area">
                            <h2 className="m-main-menu__tile__headline">Wetterwarnungen</h2>
                            <small>des Deutschen Wetterdienstes</small>
                            <div>Karte Deutscher Wetterdienst - Wetterwarnungen Rheinland-Pfalz/Saarland</div>
                            <a
                                className="sidebar-link-area__read-more"
                                href="http://www.dwd.de/DE/wetter/warnungen_gemeinden/warnkarten/warnWetter_rps_node.html?bundesland=rps"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Mehr erfahren
                            </a>
                        </div>
                        <div className="sidebar-area sidebar-link-area">
                            <h2 className="m-main-menu__tile__headline">Hochwassermanagement</h2>
                            <small>Hochwassergefahren- und Hochwasserrisikokarten</small>
                            <div>
                                Hochwassergefahren- und Hochwasserrisikokarten sind die wirksamste Informationsgrundlage
                                über die hochwassergefährdeten Flächen und das Ausmaß der dort vorhandenen Risiken.
                            </div>
                            <a
                                className="sidebar-link-area__read-more"
                                href="https://hochwassermanagement.rlp-umwelt.de/servlet/is/8662/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Mehr erfahren
                            </a>
                        </div>
                    </div>
                </div>
                <div className="m-main-menu__bottom-area">
                    <nav className="footer-nav">
                        <Link
                            to={routes.contact.path}
                            className="footer-nav__link"
                            tabIndex={tabIndex}
                            onClick={closeMenu}
                        >
                            {routes.contact.label}
                        </Link>
                        <Link
                            to={routes.imprint.path}
                            className="footer-nav__link"
                            tabIndex={tabIndex}
                            onClick={closeMenu}
                        >
                            {routes.imprint.label}
                        </Link>
                        <Link
                            to={routes.privacyPolicy.path}
                            className="footer-nav__link"
                            tabIndex={tabIndex}
                            onClick={closeMenu}
                        >
                            {routes.privacyPolicy.label}
                        </Link>
                        <Link
                            to={routes.accessibility.path}
                            className="footer-nav__link"
                            tabIndex={tabIndex}
                            onClick={closeMenu}
                        >
                            {routes.accessibility.label}
                        </Link>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default MMainMenu;
