import { Feature, Geometry } from 'geojson';
import { LeafletEventHandlerFnMap, LeafletMouseEvent, Map, Path, svg } from 'leaflet';
import { GeoJSON, useMap } from 'react-leaflet';
import { TShapefileProperties } from '../../../service/shapefileHandler';
import { checkTouchDevice, unproject } from '../../../utils/mapUtils';
import './EMap__Polygon.scss';

/**
 * Polygon Component Leaflet Layer
 * used to display and handle preAlertRegions
 */
interface IProps {
    data: Feature<Geometry, TShapefileProperties>;
    fillColor?: string;
    pane: string;
    onClick?: (e: LeafletMouseEvent, map: Map) => void;
}

const isTouchDevice = checkTouchDevice();

function layerMouseOverHandler(e: LeafletMouseEvent) {
    // @ts-expect-error Wrong type here. The property exists.
    e.originalEvent._stopped = true;
    // Obviously `Path` is the wrong type here, but GeoJSON does not have a Layer type and `Path` fits best.
    const layer = e.layer as Path;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/dot-notation
    layer.getElement()['style'].opacity = '0.7';
}

function layerMouseOutHandler(e: LeafletMouseEvent) {
    // @ts-expect-error Wrong type here. The property exists.
    e.originalEvent._stopped = true;
    // Obviously `Path` is the wrong type here, but GeoJSON does not have a Layer type and `Path` fits best.
    const layer = e.layer as Path;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/dot-notation
    layer.getElement()['style'].opacity = '1';
}

// Project approved
// eslint-disable-next-line @typescript-eslint/naming-convention
const EMap__PreAlertRegionPolygon = ({ data, fillColor, pane, onClick }: IProps) => {
    const map = useMap();

    const eventHandlers: LeafletEventHandlerFnMap = onClick && {
        click: (e: LeafletMouseEvent) => {
            // @ts-expect-error Wrong type here. The property exists.
            e.originalEvent._stopped = true;
            onClick(e, map);
        },
        mouseover: !isTouchDevice ? layerMouseOverHandler : undefined,
        mouseout: !isTouchDevice ? layerMouseOutHandler : undefined,
    };

    const opts = {
        data,
        coordsToLatLng: unproject,
        noClip: true,
        shadowPane: '',
        interactive: !!onClick,
        className: 'polygon' + (onClick ? ' polygon--has-data' : ''),
        renderer: svg({ pane: pane }),
        style: {
            fillColor: fillColor || '#fff',
            color: '#333',
            fillOpacity: 1,
            weight: 0.5,
        },
        eventHandlers,
    };

    return <GeoJSON {...opts} />;
};

export default EMap__PreAlertRegionPolygon;
