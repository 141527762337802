import {
    Chart,
    ChartData,
    ChartDataset,
    ChartOptions,
    LinearScaleOptions,
    Plugin,
    ScatterDataPoint,
    TimeScaleOptions,
} from 'chart.js';
import 'chartjs-adapter-moment';
import annotationPlugin, { AnnotationOptions } from 'chartjs-plugin-annotation';
import moment from 'moment-timezone';
import { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import chartColors from '../../../config/chart';
import { ILegend, IMeasurementSiteConfig, TMeasurementMarkType } from '../../../models/config';
import { IMeasurements, IWaterLevel } from '../../../models/misc';
import {
    formatNumber,
    momentFormatHour,
    momentFullFormat,
    momentParse,
    validateArray,
} from '../../../service/chartDataHandler';
import { useResize } from '../../../utils/hooks';
import EErrorBoundary from '../error-boundary/EErrorBoundary';
import EHelpIcon from '../help-icon/EHelpIcon';
import ELegend, { IToggleableLegend } from '../legend/ELegend';
import './EDetailChart.scss';

const mobileBreakPoint = 568;
const markTypes: TMeasurementMarkType[] = ['hsw2', 'hsw1', 'mh2', 'mh1'];

/**
 * Plugin for visualizing units on the top-left corner of DetailChart
 */
const customTitle: Plugin<'line'> = {
    id: 'customTitle',
    // No types available for customTitle Chart.js plugin.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    beforeLayout: (chart, _args, opts: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (!opts?.x?.display) {
            return;
        }

        const { ctx } = chart;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        ctx.font = opts.x.font || '12px "Helvetica Neue", Helvetica, Arial, sans-serif';

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        const { width } = ctx.measureText(opts.x.text);
        // @ts-expect-error Wrong Chart.js type. Property `right` is correct.
        chart.options.layout.padding.right = width * 1.3;
    },
    // No types available for customTitle Chart.js plugin.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    afterDraw: (chart, _args, opts: any) => {
        const {
            ctx,
            chartArea: { top },
        } = chart;

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (opts.y.display) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            ctx.fillStyle = opts.y.color || Chart.defaults.color;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            ctx.font = opts.y.font || '12px "Helvetica Neue", Helvetica, Arial, sans-serif';
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
            ctx.fillText(opts.y.text, opts.y.offsetX || 3, top + (opts.y.offsetY * -1 || -15));
        }
    },
};

/**
 * Build dataset definitions from measurements and predictions.
 */
const getDataSets = (
    levelData: IWaterLevel[],
    p10: IWaterLevel[],
    p20: IWaterLevel[],
    p30: IWaterLevel[],
    p40: IWaterLevel[],
    p50: IWaterLevel[],
    p60: IWaterLevel[],
    p70: IWaterLevel[],
    p80: IWaterLevel[],
    p90: IWaterLevel[]
): ChartDataset<'line'>[] => [
    {
        type: 'line',
        label: 'Messung',
        // We are using time series for y axis - the default LineOptions type does not reflect this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data: levelData as unknown as ScatterDataPoint[],
        fill: false,
        tension: 0.1,
        borderColor: chartColors.measurement,
        borderWidth: 2,
        backgroundColor: chartColors.measurement,
        pointRadius: 0,
        pointHitRadius: 1,
        order: 0,
    },
    {
        label: 'Max',
        // We are using time series for y axis - the default LineOptions type does not reflect this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data: p90 as unknown as ScatterDataPoint[],
        fill: '+1',
        tension: 0.1,
        // Border color is needed for tooltip.
        borderColor: chartColors.p90,
        borderWidth: 0,
        backgroundColor: chartColors.p90,
        pointRadius: 0,
        order: 4,
    },
    {
        label: '80%',
        // We are using time series for y axis - the default LineOptions type does not reflect this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data: p80 as unknown as ScatterDataPoint[],
        tension: 0.1,
        fill: '+1',
        borderWidth: 0,
        backgroundColor: chartColors.p80,
        pointRadius: 0,
        order: 3,
    },
    {
        label: '70%',
        // We are using time series for y axis - the default LineOptions type does not reflect this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data: p70 as unknown as ScatterDataPoint[],
        fill: '+1',
        tension: 0.1,
        borderWidth: 0,
        backgroundColor: chartColors.p70,
        pointRadius: 0,
        order: 2,
    },
    {
        label: '60%',
        // We are using time series for y axis - the default LineOptions type does not reflect this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data: p60 as unknown as ScatterDataPoint[],
        fill: '+1',
        tension: 0.1,
        borderWidth: 0,
        backgroundColor: chartColors.p60,
        pointRadius: 0,
        order: 1,
    },
    {
        type: 'line',
        label: 'Vorhersage',
        // We are using time series for y axis - the default LineOptions type does not reflect this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data: p50 as unknown as ScatterDataPoint[],
        fill: false,
        borderColor: chartColors.p50,
        borderWidth: 2,
        backgroundColor: chartColors.p50,
        pointRadius: 0,
        order: 0,
    },
    {
        label: '40%',
        // We are using time series for y axis - the default LineOptions type does not reflect this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data: p40 as unknown as ScatterDataPoint[],
        fill: '-1',
        tension: 0.1,
        borderWidth: 0,
        backgroundColor: chartColors.p40,
        pointRadius: 0,
        order: 1,
    },
    {
        label: '30%',
        // We are using time series for y axis - the default LineOptions type does not reflect this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data: p30 as unknown as ScatterDataPoint[],
        fill: '-1',
        tension: 0.1,
        borderWidth: 0,
        backgroundColor: chartColors.p30,
        pointRadius: 0,
        order: 2,
    },
    {
        label: '20%',
        // We are using time series for y axis - the default LineOptions type does not reflect this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data: p20 as unknown as ScatterDataPoint[],
        fill: '-1',
        tension: 0.1,
        borderWidth: 0,
        backgroundColor: chartColors.p20,
        pointRadius: 0,
        order: 3,
    },
    {
        label: 'Min',
        // We are using time series for y axis - the default LineOptions type does not reflect this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data: p10 as unknown as ScatterDataPoint[],
        fill: '-1',
        tension: 0.1,
        // Border color is needed for tooltip.
        borderColor: chartColors.p10,
        borderWidth: 0,
        backgroundColor: chartColors.p10,
        pointRadius: 0,
        order: 4,
    },
];

type TRenderArgsAnnotations = {
    [key in TMeasurementMarkType]?: AnnotationOptions<'line'>;
} & {
    currentTime?: AnnotationOptions<'line'>;
};

interface IRenderArgs {
    annotations: TRenderArgsAnnotations;
    thresholdLegends: Record<string, IToggleableLegend>;
    markLegends: Record<string, IToggleableLegend>;
    options: ChartOptions<'line'>;
    plugins: Plugin<'line'>[];
    data: ChartData<'line'>;
    p10: IWaterLevel[];
    p20: IWaterLevel[];
    p30: IWaterLevel[];
    p40: IWaterLevel[];
    p50: IWaterLevel[];
    p60: IWaterLevel[];
    p70: IWaterLevel[];
    p80: IWaterLevel[];
    p90: IWaterLevel[];
    levelData: IWaterLevel[];
}

interface IProps {
    msData: IMeasurements | null;
    msConfig: IMeasurementSiteConfig | null;
    waterlevel?: boolean;
    thresholds: Record<TMeasurementMarkType, number | null>;
    legends: Record<string, ILegend>;
    children?: React.ReactElement;
    label: string;
}

const EDetailChart = ({ children, legends, msConfig, msData, thresholds, waterlevel }: IProps) => {
    const [loading, setLoading] = useState<boolean>(!msConfig || !msData);
    const chartRef = useRef<Chart<'line', (number | ScatterDataPoint)[]>>(null);
    const selectedLegends = useRef<TMeasurementMarkType[]>();
    const windowSize = useResize();

    const chartAspectRatio = windowSize.width < mobileBreakPoint ? 1 : 2;
    const [displayLeftYAxis, setDisplayLeftYAxis] = useState(windowSize.width >= mobileBreakPoint);

    const renderArgs = useMemo((): IRenderArgs | null => {
        if (!msConfig || !msData) {
            return null;
        }

        Chart.register(annotationPlugin);

        // Validate predictions/waterlevel data.
        const p10 = validateArray(msData.predictions?.p10);
        const p20 = validateArray(msData.predictions?.p20);
        const p30 = validateArray(msData.predictions?.p30);
        const p40 = validateArray(msData.predictions?.p40);
        const p50 = validateArray(msData.predictions?.p50);
        const p60 = validateArray(msData.predictions?.p60);
        const p70 = validateArray(msData.predictions?.p70);
        const p80 = validateArray(msData.predictions?.p80);
        const p90 = validateArray(msData.predictions?.p90);
        const levelData = validateArray(msData.measurements);

        // Define how much space should be drawn between end of data and chart border.
        const xRightPadding = moment.duration(6, 'hours');
        // Define xMin and yMax.
        const xMin = msData.xMin;
        const xMax = msData.xMax ? +moment(msData.xMax).add(xRightPadding) : null;

        const yMin = msData.yMin;
        const yMax = msData.yMax;

        // Define y step size for chart ticks.
        let yStepSize = waterlevel && msData.yStepSize;
        let yStepSizeMajor = waterlevel && msData.yStepSizeMajor;
        // Calculate ticks so that at minimum 10 minor ticks appear.
        if (!yStepSize) {
            yStepSize = 10;
            if ((yMin || yMin === 0) && (yMax || yMax === 0)) {
                const yDelta = yMax - yMin;
                const minScaleTicks = 5;
                while (yStepSize > 0.01 && yDelta < yStepSize * minScaleTicks) {
                    yStepSize /= 10.0;
                }
            }
        }
        if (!yStepSizeMajor) {
            yStepSizeMajor = yStepSize * 5;
        }
        const yStepPrecision = (yStepSize + '').split('.')[1]?.length || 0;

        const unit = waterlevel ? (msConfig.isSeaSite ? 'm ü. NHN' : 'cm') : 'm\u00B3/s';

        const data: ChartData<'line'> = {
            // Hint: Colors are used two ways: primary to draw chart data and secondary to render legends and tooltips.
            datasets: getDataSets(levelData, p10, p20, p30, p40, p50, p60, p70, p80, p90),
        };

        /**
         * Initialize annotations for detail chart. First initializing fixed annotations and then thresholds.
         */
        const annotations: TRenderArgsAnnotations = {};
        const thresholdLegends: Record<string, IToggleableLegend> = {};
        const markLegends: Record<string, IToggleableLegend> = {};
        const activeLegends: TMeasurementMarkType[] = [];

        let nearestThresholdAbove: TMeasurementMarkType = null;
        let nearestThresholdBelow: TMeasurementMarkType = null;
        let hasThresholdBetween: boolean = false;

        if (waterlevel) {
            if (msConfig.mh1) {
                annotations.mh1 = {
                    display: false,
                    drawTime: 'beforeDatasetsDraw',
                    type: 'line',
                    yMin: msConfig.mh1,
                    yMax: msConfig.mh1,
                    borderColor: chartColors.mh1,
                    borderWidth: 2,
                    label: {
                        content: 'Meldehöhe',
                        enabled: true,
                        backgroundColor: 'rgba(240, 240, 240, 0)',
                        color: chartColors.mh1,
                        yAdjust: -8,
                        yPadding: 0,
                        cornerRadius: 0,
                        position: 'start',
                    },
                };
            }

            if (msConfig.mh2) {
                annotations.mh2 = {
                    display: false,
                    drawTime: 'beforeDatasetsDraw',
                    type: 'line',
                    yMin: msConfig.mh2,
                    yMax: msConfig.mh2,
                    borderColor: chartColors.mh2,
                    borderWidth: 2,
                    label: {
                        content: 'Meldehöhe',
                        enabled: true,
                        backgroundColor: 'rgba(240, 240, 240, 0)',
                        color: chartColors.mh2,
                        yAdjust: -8,
                        yPadding: 0,
                        cornerRadius: 0,
                        position: 'start',
                    },
                };
            }

            if (msConfig.hsw1) {
                annotations.hsw1 = {
                    display: false,
                    drawTime: 'beforeDatasetsDraw',
                    type: 'line',
                    yMin: msConfig.hsw1,
                    yMax: msConfig.hsw1,
                    borderColor: chartColors.hsw1.borderColor,
                    borderWidth: 3,
                    borderDash: [5, 5],
                    label: {
                        content: 'SHWM I',
                        enabled: true,
                        backgroundColor: 'rgba(240, 240, 240, 0)',
                        color: chartColors.hsw1.color,
                        yAdjust: -8,
                        yPadding: 0,
                        cornerRadius: 0,
                        position: 'start',
                    },
                };
            }

            if (msConfig.hsw2) {
                annotations.hsw2 = {
                    display: false,
                    drawTime: 'beforeDatasetsDraw',
                    type: 'line',
                    yMin: msConfig.hsw2,
                    yMax: msConfig.hsw2,
                    borderColor: chartColors.hsw2.borderColor,
                    borderWidth: 3,
                    borderDash: [5, 5],
                    label: {
                        content: 'SHWM II',
                        enabled: true,
                        backgroundColor: 'rgba(240, 240, 240, 0)',
                        color: chartColors.hsw2.color,
                        yAdjust: -8,
                        yPadding: 0,
                        cornerRadius: 0,
                        position: 'start',
                    },
                };
            }

            markTypes.forEach((element) => {
                const markValue = msConfig?.[element];
                if (markValue) {
                    // Initially show only marks within current min max range.
                    const active = !!(
                        msData.yMin &&
                        msData.yMin <= markValue &&
                        msData.yMax &&
                        markValue <= msData.yMax
                    );
                    if (active) {
                        hasThresholdBetween = true;
                        activeLegends.push(element);
                    } else if (!hasThresholdBetween && element !== 'hsw1' && element !== 'hsw2') {
                        if (
                            (!msData.yMax || markValue > msData.yMax) &&
                            (!nearestThresholdAbove || markValue < msConfig[nearestThresholdAbove])
                        ) {
                            nearestThresholdAbove = element;
                        }
                        if (
                            (!msData.yMin || markValue < msData.yMin) &&
                            (!nearestThresholdBelow || markValue > msConfig[nearestThresholdBelow])
                        ) {
                            nearestThresholdBelow = element;
                        }
                    }

                    let description: string;
                    if (element === 'hsw1' || element === 'hsw2') {
                        description = element === 'hsw1' ? 'Schifff. HW Marke (SHWM I)' : 'Schifff. HW Marke (SHWM II)';
                    } else {
                        description = 'Meldehöhe';
                    }

                    annotations[element].display = active;
                    markLegends[element] = {
                        active,
                        name: element,
                        description: description,
                        color: 'rgba(0, 0, 0, 1)',
                        sorting: null,
                    };
                }
            });
        }

        if (thresholds) {
            Object.keys(thresholds).forEach((element: TMeasurementMarkType) => {
                const threshold = thresholds[element];
                if (threshold && !legends[element]?.hiddenDetail) {
                    // Initially show only thresholds within current min max range.
                    const active = !!(
                        msData.yMin &&
                        msData.yMin <= threshold &&
                        msData.yMax &&
                        threshold <= msData.yMax
                    );
                    if (active) {
                        hasThresholdBetween = true;
                        activeLegends.push(element);
                    } else if (!hasThresholdBetween) {
                        if (
                            (!msData.yMax || threshold > msData.yMax) &&
                            (!nearestThresholdAbove || threshold < thresholds[nearestThresholdAbove])
                        ) {
                            nearestThresholdAbove = element;
                        }
                        if (
                            (!msData.yMin || threshold < msData.yMin) &&
                            (!nearestThresholdBelow || threshold > thresholds[nearestThresholdBelow])
                        ) {
                            nearestThresholdBelow = element;
                        }
                    }

                    annotations[element] = {
                        display: active,
                        drawTime: 'beforeDatasetsDraw',
                        type: 'line',
                        yMin: threshold,
                        yMax: threshold,
                        borderColor: legends[element]?.color,
                        borderWidth: 2,
                        label: {
                            content: legends[element]?.description,
                            enabled: true,
                            backgroundColor: 'rgba(240, 240, 240, 0)',
                            color: legends[element]?.color,
                            position: 'start', // 'start', 'center', 'end' or '\d%'
                            yAdjust: -8,
                            yPadding: 0,
                            cornerRadius: 0,
                        },
                    };

                    thresholdLegends[element] = { ...legends[element], active };
                }
            });
        }

        if (!selectedLegends.current) {
            // Enable nearest threshold(s).
            if (!hasThresholdBetween) {
                if (nearestThresholdAbove && !activeLegends.includes(nearestThresholdAbove)) {
                    activeLegends.push(nearestThresholdAbove);
                    annotations[nearestThresholdAbove].display = true;
                    if (markTypes.includes(nearestThresholdAbove)) {
                        markLegends[nearestThresholdAbove].active = true;
                    } else {
                        thresholdLegends[nearestThresholdAbove].active = true;
                    }
                }
                if (nearestThresholdBelow && !activeLegends.includes(nearestThresholdBelow)) {
                    activeLegends.push(nearestThresholdBelow);
                    annotations[nearestThresholdBelow].display = true;
                    if (markTypes.includes(nearestThresholdBelow)) {
                        markLegends[nearestThresholdBelow].active = true;
                    } else {
                        thresholdLegends[nearestThresholdBelow].active = true;
                    }
                }
            }

            selectedLegends.current = activeLegends;
        }
        // (Re)apply formerly selected legends.
        else {
            selectedLegends.current.forEach((markType) => {
                annotations[markType].display = true;
                if (markTypes.includes(markType)) {
                    markLegends[markType].active = true;
                } else {
                    thresholdLegends[markType].active = true;
                }
            });
        }

        annotations.currentTime = {
            drawTime: 'beforeDatasetsDraw',
            type: 'line',
            scaleID: 'x',
            value: levelData[levelData?.length - 1]?.x,
            borderWidth: 7,
            borderColor: chartColors.currentDate.backgroundColor,
            label: {
                rotation: 0,
                content: momentFormatHour(levelData[levelData?.length - 1]?.x),
                enabled: true,
                position: 'center',
                yAdjust: -20,
                backgroundColor: chartColors.currentDate.backgroundColor,
                // @ts-expect-error Valid chart property.
                fontColor: 'black',
                color: chartColors.currentDate.color,
                // Chart types are wrong here. A partial font config is working fine.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
                font: { style: 'normal' } as any,
            },
        };

        const xAxis: TimeScaleOptions = {
            type: 'time',
            min: xMin,
            max: xMax,
            time: {
                unit: 'day',
                displayFormats: {
                    day: chartAspectRatio > 1 ? 'DD.MM.YYYY' : 'DD.MM.',
                },
                // Explicitly set parser to support time zones.
                // @ts-expect-error Because we are processing the values ourseleves we do not rely on Chart.js default `number` type.
                parser: (x: string | null) => momentParse(x),
            },
            // @ts-expect-error Wrong Chart type. Not all properties have to be set.
            grid: {
                color: chartColors.chartArea.gridLineColor,
                lineWidth: 3,
            },
            ticks: {
                maxRotation: 0,
                minRotation: 0,
                padding: 5,
                font: {
                    size: 12,
                    weight: '900',
                    // @ts-expect-error Wrong Chart type. Documentation states this property exists.
                    align: 'end',
                },
            },
        };

        const yAxis: LinearScaleOptions = {
            type: 'linear',
            position: 'left',
            display: displayLeftYAxis,
            beginAtZero: !waterlevel && (!yMin || yMin < yStepSizeMajor),
            min: !waterlevel && (!yMin || yMin < yStepSizeMajor) ? 0 : undefined,
            // Add 10 cm space below and above chart data.
            grace: yStepSize,
            // @ts-expect-error Wrong Chart type. Not all properties have to be set.
            grid: {
                lineWidth: 3,
                // drawTicks: false,
                color: (ctx) => {
                    // Draw lines only for major ticks.
                    return ctx.tick.major ? chartColors.chartArea.gridLineColor : chartColors.chartArea.backgroundColor;
                },
            },
            // @ts-expect-error Wrong Chart type. Not all properties have to be set.
            ticks: {
                includeBounds: false,
                stepSize: yStepSize,
                precision: yStepPrecision,
                align: 'center',
                font: (ctx) => {
                    return ctx.tick?.major ? { weight: '900', size: 12 } : { weight: '400', size: 10.5 };
                },
            },
            afterBuildTicks: (scale) => {
                // Set major ticks.
                const precisionFactor = Math.pow(10, yStepPrecision);
                const yStepSizeMajorWeighted = Math.round(yStepSizeMajor * precisionFactor);
                scale.ticks.forEach((t) => {
                    t.major = Math.round(t.value * precisionFactor) % yStepSizeMajorWeighted === 0;
                });
            },
        };

        const options: ChartOptions<'line'> = {
            interaction: {
                intersect: false,
            },
            maintainAspectRatio: true,
            aspectRatio: chartAspectRatio,
            layout: {
                padding: {
                    bottom: 0,
                    top: 25,
                },
            },
            animation: false,
            elements: {
                point: {
                    hoverRadius: 0,
                },
            },
            responsive: true,
            plugins: {
                customTitle: {
                    y: {
                        display: true,
                        text: '[' + unit + ']',
                        offsetX: 1,
                        offsetY: 10,
                        color: chartColors.axisLabel,
                    },
                },
                tooltip: {
                    mode: 'nearestOffset',
                    // @ts-expect-error Wrong Chart type. Documentation states property exists.
                    nearestOffset: 10,
                    axis: 'x',
                    position: 'average',
                    yAlign: 'bottom',
                    // Sort tooltip items by dataset index.
                    itemSort: (ctxA, ctxB) => ctxA.datasetIndex - ctxB.datasetIndex,
                    callbacks: {
                        label: (context) => {
                            const label = context.dataset.label;
                            const rawData: IWaterLevel = context.raw as IWaterLevel;
                            if (label === 'Messung' && levelData[levelData.length - 1]?.x >= rawData.x) {
                                return label + ': ' + formatNumber(context.parsed.y) + ' ' + unit;
                            }
                            if (
                                ['Max', 'Min', 'Vorhersage'].indexOf(label) > -1 &&
                                levelData[levelData.length - 1]?.x < rawData.x
                            ) {
                                return label + ': ' + formatNumber(context.parsed.y) + ' ' + unit;
                            }
                            return null;
                        },
                        title: (context) => {
                            const rawData: IWaterLevel = context[0].raw as IWaterLevel;
                            if (rawData.x) {
                                return momentFullFormat(rawData.x) + ' Uhr';
                            }
                            return null;
                        },
                    },
                },
                // @ts-expect-error Chart types do not correctly reflect the possibility to completely disable data labels.
                datalabels: false,
                // @ts-expect-error Chart types do not correctly reflect the possibility to completely disable legends.
                legend: false,
                annotation: {
                    annotations,
                },
                decimation: {
                    // Use max 2 data points per rendered pixel keeping min/max peaks.
                    algorithm: 'min-max',
                    threshold: 2,
                },
            },
            scales: {
                x: xAxis,

                y: yAxis,

                /**
                 * This is the axis on the right side of the chart.
                 * It duplicates the one on the left for better visibility.
                 */
                y2: {
                    ...yAxis,
                    display: true,
                    position: 'right',
                    afterBuildTicks: (scale) => {
                        // Copy the ticks from the y-axis on the left.
                        scale.ticks = scale.chart.scales.y.getTicks();
                        scale.min = scale.chart.scales.y.min;
                        scale.max = scale.chart.scales.y.max;

                        yAxis.afterBuildTicks(scale);
                    },
                },
            },
            chartArea: {
                backgroundColor: chartColors.chartArea.backgroundColor,
            },
        };

        const plugins: Plugin<'line'>[] = [
            {
                id: 'chart_accessibility_plugin',
                beforeDraw: (chart) => {
                    // @ts-expect-error Wrong Chart.js type. Documentation states property exists.
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    if (chart.config.options.chartArea?.backgroundColor) {
                        const ctx = chart.ctx;
                        const chartArea = chart.chartArea;
                        ctx.save();
                        // @ts-expect-error Wrong Chart.js type. Documentation states property exists.
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
                        ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
                        ctx.fillRect(
                            chartArea.left,
                            chartArea.top,
                            chartArea.right - chartArea.left,
                            chartArea.bottom - chartArea.top
                        );
                        ctx.restore();
                    }
                },
                afterInit(chart) {
                    const canvas: HTMLCanvasElement = chart?.ctx?.canvas;
                    if (!canvas) {
                        return;
                    }
                    canvas.ariaLabel = 'Hochwasserbericht von ' + msConfig.name;
                },
            },
            customTitle,
        ];

        // Update state and set all args at once.
        return {
            annotations,
            thresholdLegends,
            markLegends,
            options,
            plugins,
            data,
            p10,
            p20,
            p30,
            p40,
            p50,
            p60,
            p70,
            p80,
            p90,
            levelData,
        };
    }, [msConfig, msData, legends, thresholds, waterlevel, chartAspectRatio, displayLeftYAxis]);

    useEffect(() => {
        if (!msConfig || !msData) {
            return;
        }
        setLoading(false);
    }, [msConfig, msData]);

    useEffect(() => {
        const chart = chartRef.current;
        if (chart) {
            // Modify chart options directly instead of updating state to prevent unnecessary recalculations and flickering.
            chart.options.aspectRatio = chartAspectRatio;
            // @ts-expect-error Wrong Chart type. Documentation states this property exists.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            chart.options.scales.x.time.displayFormats.day = chartAspectRatio > 1 ? 'DD.MM.YYYY' : 'DD.MM.';
            chart.update();
        }
    }, [chartAspectRatio]);

    // Hide left y axis on small devices.
    useEffect(() => {
        setDisplayLeftYAxis(windowSize.width >= mobileBreakPoint);
    }, [windowSize]);

    if (loading) {
        return (
            <div className="e-detail-chart__loading-window">
                <p className="e-detail-chart__loading-window__text">Wird geladen ...</p>
            </div>
        );
    }

    const {
        annotations,
        thresholdLegends,
        markLegends,
        options,
        plugins,
        data,
        p10,
        p20,
        p30,
        p40,
        p50,
        p60,
        p70,
        p80,
        p90,
        levelData,
    } = renderArgs;

    const hasPercentile =
        (p10.length || p20.length || p30.length || p40.length || p60.length || p70.length || p80.length || p90.length) >
        0;
    const hasPrediction = hasPercentile || p50.length > 0;
    const saveChartImage = (e: MouseEvent<HTMLAnchorElement>) => {
        const chart = chartRef.current;
        if (chart) {
            const a = e.target as HTMLAnchorElement;
            a.href = chart.toBase64Image();
            a.download = msConfig.name + '_' + (waterlevel ? 'Wasserstand' : 'Abfluss') + '.png';
        }
    };

    return (
        <div className="e-detail-chart">
            <EErrorBoundary errorMsg={'Messwerte konnten nicht geladen werden'}>
                <div className="e-detail-chart__detail-chart-wrapper">
                    <EHelpIcon hash={'pegel-ganglinie'} label="Hilfe zu Pegelganglinien" />

                    <Line
                        ref={chartRef}
                        className="e-detail-chart__detail-chart"
                        data={data}
                        options={options}
                        plugins={plugins}
                    />
                </div>
            </EErrorBoundary>

            <EErrorBoundary errorMsg={'Legende konnte nicht geladen werden'}>
                <div className="e-detail-chart__legend">
                    {levelData.length > 0 && (
                        <div className="e-detail-chart__legend-item">
                            <span className="e-detail-chart__legend-lines">
                                <span style={{ background: chartColors.measurement }}></span>
                            </span>
                            <span className="e-detail-chart__legend-name">Messung</span>
                        </div>
                    )}

                    {hasPrediction && (
                        <div className="e-detail-chart__legend-item">
                            <span className="e-detail-chart__legend-lines">
                                {hasPercentile && (
                                    <>
                                        <span style={{ background: chartColors.p90 }}></span>
                                        <span style={{ background: chartColors.p80 }}></span>
                                        <span style={{ background: chartColors.p70 }}></span>
                                        <span style={{ background: chartColors.p60 }}></span>
                                    </>
                                )}
                                <span style={{ background: chartColors.p50 }}></span>
                                {hasPercentile && (
                                    <>
                                        <span style={{ background: chartColors.p40 }}></span>
                                        <span style={{ background: chartColors.p30 }}></span>
                                        <span style={{ background: chartColors.p20 }}></span>
                                        <span style={{ background: chartColors.p10 }}></span>
                                    </>
                                )}
                            </span>
                            <span className="e-detail-chart__legend-name">Vorhersage</span>
                        </div>
                    )}
                    <EHelpIcon hash={'pegel-legende'} label="Hilfe zur Pegellegende" />
                </div>
            </EErrorBoundary>

            {Object.keys(markLegends).length > 0 && (
                <EErrorBoundary errorMsg={'Legende konnte nicht geladen werden'}>
                    <ELegend
                        legends={markLegends}
                        onActiveToggle={(id, item) => {
                            const index = selectedLegends.current.indexOf(id);
                            if (item.active && index === -1) {
                                selectedLegends.current.push(id);
                            } else if (!item.active && index > -1) {
                                selectedLegends.current.splice(index, 1);
                            }
                            annotations[id].display = item.active;
                            chartRef.current.update();
                        }}
                    />
                </EErrorBoundary>
            )}

            {Object.keys(thresholdLegends).length > 0 && (
                <EErrorBoundary errorMsg={'Legende konnte nicht geladen werden'}>
                    <ELegend
                        legends={thresholdLegends}
                        onActiveToggle={(id, item) => {
                            const index = selectedLegends.current.indexOf(id);
                            if (item.active && index === -1) {
                                selectedLegends.current.push(id);
                            } else if (!item.active && index > -1) {
                                selectedLegends.current.splice(index, 1);
                            }
                            annotations[id].display = item.active;
                            chartRef.current.update();
                        }}
                    />
                </EErrorBoundary>
            )}

            {children}

            <div className="e-detail-chart__legend">
                <a className="e-detail-chart__legend__save-chart-link" href="#" onClick={saveChartImage}>
                    Als Grafik herunterladen
                </a>
            </div>
        </div>
    );
};

export default EDetailChart;
