import './EHamburger.scss';

interface IProps {
    isOpen: boolean;
    toggleMenu: (value?: boolean) => void;
}

const EHamburger = (props: IProps) => {
    return (
        <div
            className={'e-hamburger' + (props.isOpen ? ' e-hamburger--open' : '')}
            onKeyPress={() => props.toggleMenu()}
            onClick={() => props.toggleMenu()}
            tabIndex={1}
            data-testid="hamburger"
        >
            <div className="e-hamburger__inner">
                <span className="e-hamburger__lines top"></span>
                <span className="e-hamburger__lines middle"></span>
                <span className="e-hamburger__lines bottom"></span>
            </div>
        </div>
    );
};

export default EHamburger;
