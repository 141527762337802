import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useLocation } from 'react-router-dom';
import { queryClient } from '../../service/globalService';
import ESnackbar from '../elements/snackbar/ESnackbar';
import LRoutes from '../routes/LRoutes';
import LBreadcrumbBar from './breadcrumb-bar/LBreadcrumbBar';
import LFooter from './footer/LFooter';
import LHeader from './header/LHeader';
import './LApp.scss';
import MatomoTracker from './matomo/MatomoTracker';

const LApp = () => {
    const { pathname } = useLocation();

    return (
        <div className="l-app">
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} />

                <MatomoTracker />

                <ESnackbar />
                <LHeader />

                <span id="back-to-top-anchor"></span>
                <main className="l-main">
                    {/* Don't show breadcrump on home page. */}
                    {pathname !== '/' && <LBreadcrumbBar />}

                    <LRoutes />
                </main>

                {/* Don't show footer on home page, it will be included in map content overlay. */}
                {pathname !== '/' && <LFooter />}
            </QueryClientProvider>
        </div>
    );
};

export default LApp;
