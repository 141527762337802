import { Feature, Geometry } from 'geojson';
import { GeoJSONOptions, LeafletMouseEvent, Map, MarkerOptions, Path, PathOptions, PolylineOptions } from 'leaflet';
import { GeoJSON, GeoJSONProps, useMap } from 'react-leaflet';
import { TShapefileProperties } from '../../../service/shapefileHandler';
import { checkTouchDevice, unproject } from '../../../utils/mapUtils';
import './EMap__Polygon.scss';

/**
 * Polygon Component Leaflet Layer
 * used to display and handle alertRegions
 */
interface IProps {
    data: Feature<Geometry, TShapefileProperties>;
    fillColor?: string;
    onClick?: (e: LeafletMouseEvent, map: Map) => void;
}

const isTouchDevice = checkTouchDevice();

function getHoverColor(fillColor: string) {
    return fillColor.toLowerCase().replace(/([0-9a-f]{2})/g, (_, p1: string) => {
        const val = parseInt(p1, 16);
        const newVal = Math.round(Math.max(0, val - val * 0.1));
        return newVal.toString(16).padStart(2, '0');
    });
}

function layerMousMoveHandler(e: LeafletMouseEvent) {
    // Obviously `Path` is the wrong type here, but GeoJSON does not have a Layer type and `Path` fits best.
    const layer = e.layer as Path;
    if (layer) {
        // @ts-expect-error Wrong type here. The property exists.
        e.originalEvent._stopped = true;

        const layerOptions = layer.options as GeoJSONOptions<Path> & PathOptions;
        const fillColor = (layerOptions.style as PathOptions).fillColor;
        if (layerOptions.fillColor === fillColor) {
            layerOptions.fillColor = getHoverColor(fillColor);
            layerOptions.weight = 1.3;
        }

        layer.redraw();
    }
}

function layerMouseOutHandler(e: LeafletMouseEvent) {
    // Obviously `Path` is the wrong type here, but GeoJSON does not have a Layer type and `Path` fits best.
    const layer = e.layer as Path;
    const layerOptions = layer.options as GeoJSONOptions<Path> & PathOptions;
    const fillColor = (layerOptions.style as PathOptions).fillColor;
    const weight = (layerOptions.style as PathOptions).weight;
    layerOptions.fillColor = fillColor;
    layerOptions.weight = weight;

    layer.redraw();
}

// Project approved
// eslint-disable-next-line @typescript-eslint/naming-convention
const EMap__AlertRegionPolygon = ({ data, fillColor, onClick }: IProps) => {
    /**
     * IMPORTANT! NO STATES HERE!!!
     * It badly influences map rendering performance. Scaling of points is ONLY
     * done using map events and native leaflet API.
     */

    const map = useMap();

    const eventHandlers = onClick && {
        click(e: LeafletMouseEvent) {
            // @ts-expect-error Wrong type here. The property exists.
            e.originalEvent._stopped = true;

            onClick(e, map);
        },
        mousemove: !isTouchDevice ? layerMousMoveHandler : undefined,
        mouseout: !isTouchDevice ? layerMouseOutHandler : undefined,
    };

    const opts: GeoJSONProps & PolylineOptions & MarkerOptions = {
        data,
        coordsToLatLng: unproject,
        noClip: true,
        shadowPane: '',
        interactive: !!onClick,
        className: 'polygon' + (onClick ? ' polygon--has-data' : ''),
        style: {
            fillColor: fillColor || '#fff',
            color: '#333',
            fillOpacity: 1,
            weight: 0.5,
        },
        eventHandlers,
    };

    return <GeoJSON {...opts} />;
};

export default EMap__AlertRegionPolygon;
