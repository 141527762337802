const baseUrl = process.env.REACT_APP_API_URL;

const apiPaths = {
    base: baseUrl,
    index: '/index',
    config: '/config',
    statusReport: '/status-report',
    alertRegion: '/alert-region/{id}',
    riverArea: '/river-area',
    measurmentSite: '/measurement-site',
    partials: '/static/shared/partials',
};

export default apiPaths;
