import { ReactNode } from 'react';
import IconAlert from '../../../assets/images/icon-alert.svg';
import IconInfo from '../../../assets/images/icon-info.svg';
import IconSuccess from '../../../assets/images/icon-success.svg';
import EHelpIcon, { IHelpIconProps } from '../help-icon/EHelpIcon';
import './EMessage.scss';

const iconMap = {
    info: IconSuccess,
    warning: IconInfo,
    error: IconAlert,
};

interface IProps {
    type?: 'none' | 'info' | 'warning' | 'error';
    showIcon?: boolean;
    iconUrl?: string;
    helpIcon?: IHelpIconProps;
    highlightColor?: string;

    children?: ReactNode;
}

const EMessage = ({ children, helpIcon, highlightColor, iconUrl, showIcon, type = 'none' }: IProps) => (
    <div className={'e-message e-message--type-' + type}>
        <div className="e-message__icon-wrap" style={highlightColor && { backgroundColor: highlightColor }}>
            {showIcon && type !== 'none' && <img className="e-message__icon" src={iconUrl || iconMap[type]} alt="" />}
        </div>

        <div className="e-message__content-wrap">
            {helpIcon && <EHelpIcon {...helpIcon} />}
            {children && <div className="e-message__content-inner">{children}</div>}
        </div>
    </div>
);

export default EMessage;
